import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import AddRegionModal from "./AddRegionModal";

const AdminRegionList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [regions, setRegions] = useState([]);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [regionsPerPage] = useState(20);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [selectedRegion, setSelectedRegion] = useState({
    id: null,
    regionName: "",
    regionCode: "",
  });

  const fetchRegions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/regions`);

      setRegions(response.data);
      setFilteredRegions(response.data);
      // console.log("RegionList: " + JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    const filtered = regions.filter((region) =>
      region.regionName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredRegions(filtered);
    setCurrentPage(1);
  }, [searchQuery, regions]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleAddRegionClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditRegion = (region) => {
    setSelectedRegion(region);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedRegion(null);
  };

  const handleSaveChanges = async () => {
    if (!selectedRegion.regionCode || !selectedRegion.regionName) {
      setValidationError("Please Enter All Fields");
      return;
    }

    try {
      const response = await axios.put(`${BASE_URL}/api/admin/editregion`, {
        regionId: selectedRegion.id,
        regionName: selectedRegion.regionName,
        regionCode: selectedRegion.regionCode,
      });
      if (response.status === 200) {
        fetchRegions();
        handleCloseEditModal();
      } else {
        setValidationError("Error: " + response.data.error);
        console.error("Failed to update region details");
      }
    } catch (error) {
      console.error("Error updating region details:", error);
    }
  };

  const indexOfLastRegion = currentPage * regionsPerPage;
  const indexOfFirstRegion = indexOfLastRegion - regionsPerPage;
  const currentRegions = filteredRegions.slice(
    indexOfFirstRegion,
    indexOfLastRegion
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredRegions.length / regionsPerPage);

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <div className="search-container mt-3">
            <button
              className="btn btn-addCredential"
              onClick={handleAddRegionClick}
            >
              Add Region
            </button>
            <div
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search by region name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
                />
                <span className="search-icon">
                  <IoSearch />
                </span>
              </div>
            </div>
          </div>

          <div className="mt-5 text-center">
            {/* <div class="TableHeaderMain mb-3">
                <h6 class="TableHeaderText">Branch List</h6>
              </div> */}
            <h5 className="pb-3" class="TableHeaderText">
              Region List
            </h5>
            <div className="table-responsive py-1">
              <table className="mx-auto table table-striped table-list">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Region Code</th>
                    <th>Region Name</th>
                    {/* Add this column */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRegions.length > 0 ? (
                    currentRegions.map((region, index) => (
                      <tr key={region.id}>
                        <td>
                          {index + 1 + (currentPage - 1) * regionsPerPage}
                        </td>
                        <td>{region.regionCode}</td>
                        <td>{region.regionName}</td>

                        {/* Display branch code */}
                        <td>
                          <button
                            className="btn btn-edit"
                            title="Edit"
                            onClick={() => handleEditRegion(region)}
                          >
                            <MdModeEdit />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No regions found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <Pagination className="me-auto custom-pagination">
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev
                onClick={() =>
                  paginate(currentPage > 1 ? currentPage - 1 : currentPage)
                }
              />
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (number) => (
                  <Pagination.Item
                    className="active"
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                onClick={() =>
                  paginate(
                    currentPage > 1 && currentPage < totalPages
                      ? currentPage + 1
                      : currentPage
                  )
                }
              />
              <Pagination.Last onClick={() => paginate(totalPages)} />
            </Pagination>
          </div>
        </div>
      </div>
      <AddRegionModal
        show={isModalOpen}
        handleClose={handleCloseModal}
        fetchRegions={fetchRegions}
      />
      {selectedRegion && (
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Region</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editRegionCode">
                <Form.Label className="pt-2">Region Code</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedRegion.regionCode}
                  onChange={(e) =>
                    setSelectedRegion({
                      ...selectedRegion,
                      regionCode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="editRegionName">
                <Form.Label className="pt-2">Region Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedRegion.regionName}
                  onChange={(e) =>
                    setSelectedRegion({
                      ...selectedRegion,
                      regionName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <h6 className="text-danger">{validationError}</h6>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AdminRegionList;
