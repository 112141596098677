import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import AddDivisionModal from "./AddDivisionModal";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import Select from "react-select";

const AdminDivisionList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [filteredDivisions, setFilteredDivisions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [divisionsPerPage] = useState(20);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [regions, setRegions] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [selectedDivision, setSelectedDivision] = useState({
    id: null,
    divisionName: "",
    divisionCode: "",
    regionId: null,
  });

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/divisions`);

      setDivisions(response.data);
      setFilteredDivisions(response.data);
      //   console.log("DivisionList: " + JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    fetchDivisions();
  }, []);

  const fetchRegions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/regions`);

      setRegions(response.data);
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  useEffect(() => {
    const filtered = divisions.filter((division) =>
      division.divisionName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDivisions(filtered);
    setCurrentPage(1);
  }, [searchQuery, divisions]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleAddDivisionClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditDivision = (division) => {
    fetchRegions();
    setSelectedDivision({
      ...division,
      regionId: {
        value: division.region.id,
        label: division.region.regionName,
      },
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedDivision(null);
  };

  const handleSaveChanges = async () => {
    if (
      !selectedDivision.divisionCode ||
      !selectedDivision.divisionName ||
      !selectedDivision.regionId
    ) {
      setValidationError("Please Enter All Fields");
      return;
    }

    try {
      //   console.log("selectedDivision: " + JSON.stringify(selectedDivision));
      const response = await axios.put(`${BASE_URL}/api/admin/editdivision`, {
        divisionId: selectedDivision.id,
        divisionName: selectedDivision.divisionName,
        divisionCode: selectedDivision.divisionCode,
        regionId: selectedDivision.regionId.value,
      });
      if (response.status === 200) {
        fetchDivisions();
        handleCloseEditModal();
      } else {
        setValidationError("Error: " + response.data.error);
        console.error("Failed to update division details");
      }
    } catch (error) {
      console.error("Error updating division details:", JSON.stringify(error));
    }
  };

  const indexOfLastDivision = currentPage * divisionsPerPage;
  const indexOfFirstDivision = indexOfLastDivision - divisionsPerPage;
  const currentDivisions = filteredDivisions.slice(
    indexOfFirstDivision,
    indexOfLastDivision
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredDivisions.length / divisionsPerPage);

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <div className="search-container mt-3">
            <button
              className="btn btn-addCredential"
              onClick={handleAddDivisionClick}
            >
              Add Division
            </button>
            <div
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search by division name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
                />
                <span className="search-icon">
                  <IoSearch />
                </span>
              </div>
            </div>
          </div>

          <div className="mt-5 text-center">
            {/* <div class="TableHeaderMain mb-3">
                <h6 class="TableHeaderText">Branch List</h6>
              </div> */}
            <h5 className="pb-3" class="TableHeaderText">
              Division List
            </h5>
            <div className="table-responsive py-1">
              <table className="mx-auto table table-striped table-list">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Division Code</th>
                    <th>Division Name</th>
                    <th>Region Code</th>
                    <th>Region Name</th>
                    {/* Add this column */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDivisions.length > 0 ? (
                    currentDivisions.map((division, index) => (
                      <tr key={division.id}>
                        <td>
                          {index + 1 + (currentPage - 1) * divisionsPerPage}
                        </td>
                        <td>{division.divisionCode}</td>
                        <td>{division.divisionName}</td>
                        <td>{division.region.regionCode}</td>
                        <td>{division.region.regionName}</td>

                        {/* Display branch code */}
                        <td>
                          <button
                            className="btn btn-edit"
                            title="Edit"
                            onClick={() => handleEditDivision(division)}
                          >
                            <MdModeEdit />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No divisions found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <Pagination className="me-auto custom-pagination">
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev
                onClick={() =>
                  paginate(currentPage > 1 ? currentPage - 1 : currentPage)
                }
              />
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (number) => (
                  <Pagination.Item
                    className="active"
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                onClick={() =>
                  paginate(
                    currentPage > 1 && currentPage < totalPages
                      ? currentPage + 1
                      : currentPage
                  )
                }
              />
              <Pagination.Last onClick={() => paginate(totalPages)} />
            </Pagination>
          </div>
        </div>
      </div>
      <AddDivisionModal
        show={isModalOpen}
        handleClose={handleCloseModal}
        fetchDivisions={fetchDivisions}
      />
      {selectedDivision && (
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Division</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editRegion">
                <Form.Label className="pt-2">Region</Form.Label>
                <Select
                  value={selectedDivision.regionId}
                  onChange={(selectedOption) =>
                    setSelectedDivision({
                      ...selectedDivision,
                      regionId: selectedOption,
                    })
                  }
                  options={regions.map((region) => ({
                    value: region.id,
                    label: region.regionName,
                  }))}
                  isClearable
                  placeholder="---Select Region---"
                  className="w-100"
                />
              </Form.Group>
              <Form.Group controlId="editDivisionCode">
                <Form.Label className="pt-2">Division Code</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedDivision.divisionCode}
                  onChange={(e) =>
                    setSelectedDivision({
                      ...selectedDivision,
                      divisionCode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="editDivisionName">
                <Form.Label className="pt-2">Division Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedDivision.divisionName}
                  onChange={(e) =>
                    setSelectedDivision({
                      ...selectedDivision,
                      divisionName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <h6 className="text-danger">{validationError}</h6>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AdminDivisionList;
