import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { BASE_URL } from "../env";
import ApprovedMemberDetailsModal from "../components/ApprovedMemberDetailsModal";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import { CSVLink } from "react-csv";
import { FaFileUpload } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa6";
import { BiSolidMessageAdd } from "react-icons/bi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DisbursedExcelUploadModal from "../components/DisbursedExcelUploadModal";
import LoanDisbursedDetailModal from "../components/LoanDisbursedDetailModal";
const MergedLoanDetails = () => {
  const navigate = useNavigate();
  const { adminUser } = useAdminAuth();
  const [memberDetails, setMemberDetails] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [showApprovedMemberModal, setShowApprovedMemberModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoanModal, setShowLoanModal] = useState(false);

  const itemsPerPage = 10;

  const [data, setData] = useState([]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    if (adminUser.role === "superadmin") {
      fetchMemberDetails();
    } else {
      fetchDivisionalMemberDetails(adminUser.username);
    }
  }, [adminUser]);

  const fetchMemberDetails = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/superadmin/memberdetails?status=disbursed`
      );

      const formattedData = response.data.map((member) => ({
        ...member,
        appliedDate: new Date(member.appliedDate).toLocaleDateString("en-GB"),
      }));
      setData(response.data);
      setMemberDetails(formattedData);
    } catch (error) {
      console.error("Error fetching member details:", error.response.data);
    }
  };

  const fetchDivisionalMemberDetails = async (username) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/divisional/memberdetails/${username}?status=accepted`
      );
      const formattedData = response.data.map((member) => ({
        ...member,
      }));
      console.log(response.data, "response format");
      setData(response.data);
      setMemberDetails(formattedData);
    } catch (error) {
      console.error(
        "Error fetching divisional member details:",
        error.response.data
      );
    }
  };

  const toggleModal = (member) => {
    setSelectedMember(member);
    setShowApprovedMemberModal(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return moment(date).format("DD/MM/YYYY");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredMembers = memberDetails.filter((member) => {
    const searchFields = [
      member.memberDetails.memberName,
      member.memberDetails.phoneNumber,
      // Add other fields if needed
    ];

    return searchFields.some((field) =>
      field.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMembers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMembers.length / itemsPerPage);

  const csvHeaders = [
    { label: "Member Name", key: "memberName" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Nominee Name", key: "nomineeNames" },
    { label: "User Name", key: "username" },
    { label: "Branch Name", key: "branchName" },
    { label: "Applied Date", key: "appliedDate" },
    { label: "Date of Birth", key: "memberDateOfBirth" },
    { label: "Age", key: "memberAge" },
    { label: "Residence Type", key: "residenceType" },
    { label: "Aadhar No", key: "aadharNo" },
    { label: "PAN or Voter ID", key: "panOrVoterId" },
    { label: "Family Card No", key: "familyCardNo" },
    { label: "Ration Shop No", key: "rationShopNo" },
    { label: "Ration Shop Name", key: "rationShopName" },
    { label: "Door No", key: "doorNo" },
    { label: "Street No", key: "streetNo" },
    { label: "Panchayat", key: "panchayat" },
    { label: "Taluk", key: "taluk" },
    { label: "District", key: "district" },
    { label: "Pincode", key: "pincode" },
    { label: "Document Status", key: "documentStatus" },
    { label: "Nominee Loan ID", key: "nomineeLoanId" },
    { label: "Nominee Relation Type", key: "nomineeRelationType" },
    { label: "Nominee Date of Birth", key: "nomineeDateOfBirth" },
    { label: "Nominee Age", key: "nomineeAge" },
    { label: "Nominee Aadhar No", key: "nomineeAadharNo" },
    { label: "Nominee PAN or Voter ID", key: "nomineePanOrVoterId" },
    { label: "Nominee Phone Number", key: "nomineePhoneNumber" },
    { label: "Nominee Door No", key: "nomineeDoorNo" },
    { label: "Nominee Street No", key: "nomineeStreetNo" },
    { label: "Nominee Panchayat", key: "nomineePanchayat" },
    { label: "Nominee Taluk", key: "nomineeTaluk" },
    { label: "Nominee District", key: "nomineeDistrict" },
    { label: "Nominee Pincode", key: "nomineePincode" },
    { label: "Applied Date", key: "nomineeAppliedDate" },
    { label: "Business Details", key: "businessDetails" },
    { label: "Loan Details", key: "loanDetails" },
    { label: "Proposed Loan Details", key: "proposedLoanDetails" },
  ];

  const csvData = data.flatMap((member) => {
    return member.nomineeDetails.map((nominee) => ({
      memberName: member.memberDetails?.memberName || "",
      phoneNumber: member.memberDetails?.phoneNumber || "",
      nomineeNames: member.nomineeDetails
        .map((nominee) => nominee.nomineeName)
        .join(", "),
      username: member.fieldManagerDetails?.username || "",
      branchName: member.fieldManagerDetails?.branchName || "",
      appliedDate: formatDate(nominee?.appliedDate) || "",
      memberDateOfBirth: member.memberDetails?.dateOfBirth || "",
      memberAge: member.memberDetails?.age || 0,
      residenceType: member.memberDetails?.residenceType || "",
      aadharNo: `${member.memberDetails?.aadharNo || ""}`,
      panOrVoterId: `${member.memberDetails?.panOrVoterId || ""}`,
      familyCardNo: `${member.memberDetails?.familyCardNo || ""}`,
      rationShopNo: `${member.memberDetails?.rationShopNo || ""}`,
      rationShopName: member.memberDetails?.rationShopName || "",
      doorNo: member.memberDetails?.doorNo || "",
      streetNo: member.memberDetails?.streetNo || "",
      panchayat: member.memberDetails?.panchayat || "",
      taluk: member.memberDetails?.taluk || "",
      district: member.memberDetails?.district || "",
      pincode: member.memberDetails?.pincode || "",
      documentStatus: member.memberDetails?.documentStatus || "",
      nomineeLoanId: nominee?.nomineeLoanId || "",
      nomineeRelationType: nominee?.nomineeRelationType || "",
      nomineeDateOfBirth: nominee?.dateOfBirth || "",
      nomineeAge: nominee?.age || 0,
      nomineeAadharNo: `${nominee?.aadharNo || ""}`,
      nomineePanOrVoterId: `${nominee?.panOrVoterId || ""}`,
      nomineePhoneNumber: `${nominee?.phoneNumber || ""}`,
      nomineeDoorNo: nominee?.doorNo || "",
      nomineeStreetNo: nominee?.streetNo || "",
      nomineePanchayat: nominee?.panchayat || "",
      nomineeTaluk: nominee?.taluk || "",
      nomineeDistrict: nominee?.district || "",
      nomineePincode: nominee?.pincode || "",
      nomineeAppliedDate: formatDate(nominee?.appliedDate) || "",

      businessDetails: `"${member.businessDetails
        .map((detail) => {
          return `Source of Income: ${detail.sourceOfIncome}, Amount: ${detail.amount}, Date: ${detail.date}, Relationship Type: ${detail.relationshipType}, Position: ${detail.position}, Bank Name: ${detail.bankName}, IFSC Code: ${detail.ifscCode}, Account No: ${detail.accountNo}`;
        })
        .join("; ")}"`,

      loanDetails: member.loanDetails
        .map((loan) => {
          return `Loan ID: ${loan.loanDetailId}, Loan: ${loan.loan}, Loan Date: ${loan.loanDate}, Loan Amount: ${loan.loanAmount}, EMI Date: ${loan.emiDate}, Monthly EMI: ${loan.monthlyEmi}, Tenure to Complete: ${loan.tenureToComplete}, Bank Name: ${loan.bankName}, Loan Company Name: ${loan.loanCompanyName}`;
        })
        .join("; "),

      proposedLoanDetails: member.proposedLoanDetails
        .map((proposedLoan) => {
          return `Proposed Loan ID: ${proposedLoan.proposedLoanDetailId}, Loan Amount: ${proposedLoan.loanAmount}, Monthly EMI: ${proposedLoan.monthlyEmi}, EMI Start Date: ${proposedLoan.emiStartDate}, EMI End Date: ${proposedLoan.emiEndDate}, Bank Name: ${proposedLoan.bankName}, Account Number: ${proposedLoan.accountNumber}, IFSC Code: ${proposedLoan.ifscCode}`;
        })
        .join("; "),
    }));
  });

  return (
    <div className="dashboard-over">
      <div className="d-flex ">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{ overflowY: "scroll", height: "100vh", scrollbarWidth: 'none' }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <h6 className="mt-2">Loan Disbursed List</h6>
          <div className="search-container">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search by any field"
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
              <span className="search-icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="mb-3">
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`Approved_member_details_${new Date().toLocaleDateString()}.csv`}
              className="btn btn-addCredential"
            >
              Excel Download
            </CSVLink>
            <button
              onClick={() => setShowModal(true)}
              className="btn btn-success mx-2"
            >
              Excel Upload
            </button>
          </div>

          <div className="table-responsive pt-3">
            <table className="table table-striped table-list ">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Member Name</th>
                  <th>Phone Number</th>

                  <th>User Name</th>
                  <th>Applied Date</th>
                  {/* <th>Message</th>
                   */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((member, index) => (
                    <tr key={index}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>{member.memberDetails.memberName}</td>
                      <td>{member.memberDetails.phoneNumber}</td>

                      <td>{member.fieldManagerDetails.username}</td>
                      <td>
                        {member.memberDetails.date
                          ? member.memberDetails.date
                          : member.nomineeDetails.length > 0
                            ? formatDate(member.nomineeDetails[0].appliedDate)
                            : "No Date Available"}
                      </td>
                      {/* <td>
                        Admin : {member.memberDetails.adminMessage}
                        <br />
                        Manager : {member.memberDetails.fieldmanagermessage}
                      </td> */}
                      <td>
                        <button
                          className="btn btn-addCredential btn-sm me-2 my-2"
                          onClick={() => toggleModal(member)}
                        >
                          <FaRegEye size={20} />
                        </button>
                        <button
                          className="btn btn-danger btn-sm me-2 my-2"
                          onClick={() => {
                            setSelectedMember(member);
                            setShowLoanModal(true);
                          }}
                        >
                          View LAN
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No members found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <nav className="custom-pagination">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link mx-2"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                <li key={currentPage} className={`page-item active`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage)}
                  >
                    {/* {currentPage} */}
                    Page {currentPage} of {totalPages}, Total Data :{" "}
                    {memberDetails.length}
                  </button>
                </li>
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link mx-2"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          )}

          {selectedMember && (
            <ApprovedMemberDetailsModal
              show={showApprovedMemberModal}
              handleClose={() => setShowApprovedMemberModal(false)}
              selectedMember={selectedMember}
            />
          )}
          <DisbursedExcelUploadModal
            show={showModal}
            handleClose={() => setShowModal(false)}
          />
        </div>
        <LoanDisbursedDetailModal
          show={showLoanModal}
          selectedMember={selectedMember}
          handleClose={() => setShowLoanModal(false)}
        />
      </div>
    </div>
  );
};

export default MergedLoanDetails;
