import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";

const ApproveSanctionCommitteeModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  loanAmount,
  setLoanAmount,
  proposedLoanAmount,
}) => {
  const [validationError, setValidationError] = useState("");

  const handleClick = () => {
    if (!loanAmount) {
      setValidationError("Please fill all fields");
    } else {
      setValidationError("");
      handleConfirm();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <Form.Group>
          <Form.Label>Proposed Loan Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Proposed Loan Amount"
            value={proposedLoanAmount}
            // onChange={(event) => {
            //   setLoanAmount(event.target.value);
            // }}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
            onWheel={(e) => e.preventDefault()}
            onFocus={(e) => {
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              });
            }}
            onBlur={(e) => {
              e.target.removeEventListener("wheel", (e) => e.preventDefault());
            }}
            step="1"
            readOnly
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sanctioned Loan Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Sanctioned Loan Amount"
            value={loanAmount}
            onChange={(event) => {
              setLoanAmount(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
            onWheel={(e) => e.preventDefault()}
            onFocus={(e) => {
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              });
            }}
            onBlur={(e) => {
              e.target.removeEventListener("wheel", (e) => e.preventDefault());
            }}
            step="1"
            required
          />
        </Form.Group>
        <p className="text-danger">{validationError}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick}>
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveSanctionCommitteeModal;
