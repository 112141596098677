import { createContext, useContext, useState, useEffect } from "react";

const AdminAuthContext = createContext();

const AdminAuthProvider = ({ children }) => {
  const [adminUser, setAdminUser] = useState(() => {
    const storedAdminUser = localStorage.getItem("adminUser");
    return storedAdminUser ? JSON.parse(storedAdminUser) : null;
  });
  const[pendingSelectedMember,setPendingSelectedMember]=useState();

  useEffect(() => {
    localStorage.setItem("adminUser", JSON.stringify(adminUser));
  }, [adminUser]);

  const adminLogin = (userData) => {
    setAdminUser(userData);
  };

  const adminLogout = () => {
    setAdminUser(null);
  };

  console.log(adminUser,"admin side")

  return (
    <AdminAuthContext.Provider value={{ adminUser, adminLogin, adminLogout,setPendingSelectedMember,pendingSelectedMember }}>
    {children}
  </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => {
    const context = useContext(AdminAuthContext);
  
    if (!context) {
      throw new Error("useAdminAuth must be used within an AdminAuthProvider");
    }
  
    return context;
  };
  

export default AdminAuthProvider;
