import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../env";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const AddDivisionModal = ({ show, handleClose, fetchDivisions }) => {
  const [divisionCode, setDivisionCode] = useState("");
  const [divisionName, setDivisionName] = useState("");
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [validationError, setValidationError] = useState("");

  const fetchRegions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/regions`);

      setRegions(response.data);
    } catch (error) {
      console.error("Error fetching divisions:", error);
    }
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const handleChangeRegion = (selectedOption) => {
    setSelectedRegion(selectedOption);
  };

  const handleAddDivision = async () => {
    if (!divisionCode || !divisionName || !selectedRegion) {
      setValidationError("Please Enter All Fields");
      return;
    }
    try {
      const response = await axios.post(`${BASE_URL}/api/create/division`, {
        divisionName,
        divisionCode,
        regionId: selectedRegion.value,
      });

      if (response.data.message) {
        setDivisionName("");
        setDivisionCode("");
        setSelectedRegion(null);
        handleClose();
        fetchDivisions();
        toast.success("Division added Sucessfully");
      } else if (response.data.error) {
        setValidationError("Error: " + response.data.error);
      }
    } catch (error) {
      handleClose();
      setDivisionName("");
      setDivisionCode("");
      setSelectedRegion(null);
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Division</p>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <p>Region</p>
            <Select
              value={selectedRegion}
              onChange={handleChangeRegion}
              options={regions.map((region) => ({
                value: region.id,
                label: region.regionName,
              }))}
              isClearable
              placeholder="---Select Region---"
              className="w-100"
            />
            <p>Division Code</p>
            <Form.Control
              type="text"
              placeholder="Enter Division Code"
              value={divisionCode}
              onChange={(e) => setDivisionCode(e.target.value)}
            />
            <p className="mt-2">Division Name</p>
            <Form.Control
              type="text"
              placeholder="Enter Division Name"
              value={divisionName}
              onChange={(e) => setDivisionName(e.target.value)}
            />
            <h6 className="text-danger">{validationError}</h6>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-danger btn"
            style={{ color: "white" }}
            onClick={handleClose}
          >
            Close
          </button>
          <button className="btn btn-addCredential" onClick={handleAddDivision}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* Render ToastContainer */}
      <ToastContainer position="top-center" />
    </>
  );
};

export default AddDivisionModal;
