import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { BASE_URL } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminAddLoanTenureModal = ({ show, handleClose }) => {
  const [loanFrequencies, setLoanFrequencies] = useState([]);
  const [loanTenuresWithFrequency, setLoanTenuresWithFrequency] = useState([]);
  const [loanFrequency, setLoanFrequency] = useState(null);
  const [loanTenure, setLoanTenure] = useState("");

  useEffect(() => {
    if (show) {
      fetchLoanFrequencies();
      fetchLoanTenuresWithFrequency();
    }
  }, [show]);

  const fetchLoanFrequencies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/loan/frequencies`);
      setLoanFrequencies(response.data.loanFrequencies);
    } catch (error) {
      console.error("Error fetching loan frequencies:", error);
    }
  };

  const fetchLoanTenuresWithFrequency = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/fetchLoanTenureWithFrequency`
      );
      setLoanTenuresWithFrequency(response.data.loanTenuresWithFrequency);
    } catch (error) {
      console.error("Error fetching loan tenures:", error);
    }
  };

  const handleChangeLoanFrequency = (selectedOption) => {
    setLoanFrequency(selectedOption);
  };

  const handleAddLoanTenure = async () => {
    if (!loanFrequency || !loanTenure) {
      toast.error("Please select a loan frequency and enter a loan tenure");
      return;
    }

    const normalizedLoanTenure = loanTenure.toLowerCase();
    const existingTenure = loanTenuresWithFrequency.find(
      (tenure) =>
        tenure.loanTenure.toLowerCase() === normalizedLoanTenure &&
        tenure.frequencyId === loanFrequency.value
    );

    if (existingTenure) {
      toast.error("Loan Tenure already exists for the selected frequency!");
      
      return;
    }

    try {
      await axios.post(`${BASE_URL}/admin/addLoanTenure`, {
        loanFrequencyId: loanFrequency.value,
        loanTenure: normalizedLoanTenure,
      });

      handleClose();
      setLoanFrequency(null);
      setLoanTenure("");
      toast.success("Loan Tenure added successfully!");
    } catch (error) {
      toast.error(error.response.data.message || "An error occurred");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Loan Tenure</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          value={loanFrequency}
          onChange={handleChangeLoanFrequency}
          options={loanFrequencies.map((frequency) => ({
            value: frequency.id,
            label: frequency.loanfrequency,
          }))}
          placeholder="Select Loan Frequency"
          isClearable
        />
        <Form.Group controlId="loanTenure">
          <Form.Label className="pt-2">Loan Tenure</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Loan Tenure"
            value={loanTenure}
            onChange={(e) => setLoanTenure(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Close
        </button>
        <button className="btn btn-primary" onClick={handleAddLoanTenure}>
          Add
        </button>
      </Modal.Footer>
      <ToastContainer position="top-center" />
    </Modal>
  );
};

export default AdminAddLoanTenureModal;
