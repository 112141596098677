import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { BASE_URL } from "../env";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";

const DisbursedMemberDetail = () => {
  const { adminUser } = useAdminAuth();
  const [disbursedLoanDetails, setDisbursedLoanDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    fetchDisbursedLoanDetails();
  }, [adminUser]);

  const fetchDisbursedLoanDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/disbursedmemberlist`);
      setDisbursedLoanDetails(response.data.data);
    } catch (error) {
      console.error(
        "Error fetching disbursed loan details:",
        error.response.data
      );
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredMembers = disbursedLoanDetails.filter((member) => {
    const searchFields = [
      member.Division,
      member.BranchName,
      member.FundingAgency,
      member.Product,
      member.Tenure,
      member.VTC,
      member.MemberID,
      member.MemberName,
      member.AadhaarNumber,
      member.LANNumber,
      member.GTLanNumber,
      member.ENachName,
      member.MobileNumber,
      member.LoanDate,
      member.LoanAmount,
      member.SBAccountNumber,
      member.HouseNumber,
      member.Street,
      member.Locality,
      member.State,
      member.PINCode,
      member.ECSDate,
      member.EmiStartDate,
      member.EmiEndDate,
      member.EmiAmount,
      member.EcsBankName,
      member.EcsName,
      member.IfscCode,
      member.EscAccNo,
      member.BankLinkedMobileNumber,
    ];

    return searchFields.some((field) =>
      field && field.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMembers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMembers.length / itemsPerPage);

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{ overflowY: "scroll", height: "100vh", scrollbarWidth: 'none' }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <h6 className="mt-2">Merged Member Details</h6>
          <div className="search-container">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search by any field"
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
              <span className="search-icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="table-responsive pt-3">
            <table className="table table-striped table-list">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Division</th>
                  <th>Branch Name</th>
                  <th>Funding Agency</th>
                  <th>Product</th>
                  <th>Tenure</th>
                  <th>VTC</th>
                  <th>Member ID</th>
                  <th>Member Name</th>
                  <th>Aadhaar Number</th>
                  <th>LAN Number</th>
                  <th>GT LAN Number</th>
                  <th>ENach Name</th>
                  <th>Mobile Number</th>
                  <th>Loan Date</th>
                  <th>Loan Amount</th>
                  <th>SB Account Number</th>
                  <th>House Number</th>
                  <th>Street</th>
                  <th>Locality</th>
                  <th>State</th>
                  <th>PIN Code</th>
                  <th>ECS Date</th>
                  <th>EMI Start Date</th>
                  <th>EMI End Date</th>
                  <th>EMI Amount</th>
                  <th>ECS Bank Name</th>
                  <th>ECS Name</th>
                  <th>IFSC Code</th>
                  <th>ESC AC No</th>
                  <th>Bank AC Linked Mobile</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((member, index) => (
                    <tr key={index}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>{member.Division || "N/A"}</td>
                      <td>{member.BranchName || "N/A"}</td>
                      <td>{member.FundingAgency || "N/A"}</td>
                      <td>{member.Product || "N/A"}</td>
                      <td>{member.Tenure || "N/A"}</td>
                      <td>{member.VTC || "N/A"}</td>
                      <td>{member.MemberID || "N/A"}</td>
                      <td>{member.MemberName || "N/A"}</td>
                      <td>{member.AadhaarNumber || "N/A"}</td>
                      <td>{member.LANNumber || "N/A"}</td>
                      <td>{member.GTLanNumber || "N/A"}</td>
                      <td>{member.ENachName || "N/A"}</td>
                      <td>{member.MobileNumber || "N/A"}</td>
                      <td>{member.LoanDate || "N/A"}</td>
                      <td>{member.LoanAmount || "N/A"}</td>
                      <td>{member.SBAccountNumber || "N/A"}</td>
                      <td>{member.HouseNumber || "N/A"}</td>
                      <td>{member.Street || "N/A"}</td>
                      <td>{member.Locality || "N/A"}</td>
                      <td>{member.State || "N/A"}</td>
                      <td>{member.PINCode || "N/A"}</td>
                      <td>{member.ECSDate || "N/A"}</td>
                      <td>{member.EmiStartDate || "N/A"}</td>
                      <td>{member.EmiEndDate || "N/A"}</td>
                      <td>{member.EmiAmount || "N/A"}</td>
                      <td>{member.EcsBankName || "N/A"}</td>
                      <td>{member.EcsName || "N/A"}</td>
                      <td>{member.IfscCode || "N/A"}</td>
                      <td>{member.EscAccNo || "N/A"}</td>
                      <td>{member.BankLinkedMobileNumber || "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="75" className="text-center">
                      No members found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {totalPages > 1 && (
            <nav className="custom-pagination">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link mx-2"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                <li key={currentPage} className={`page-item active`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage)}
                  >
                    Page {currentPage} of {totalPages}, Total Data :{" "}
                    {filteredMembers.length}
                  </button>
                </li>
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link mx-2"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisbursedMemberDetail;
