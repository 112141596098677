import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../env";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddRegionModal = ({ show, handleClose, fetchRegions }) => {
  const [regionCode, setRegionCode] = useState("");
  const [regionName, setRegionName] = useState("");
  const [validationError, setValidationError] = useState("");

  const handleAddRegion = async () => {
    if (!regionCode || !regionName) {
      setValidationError("Please Enter All Fields");
      return;
    }
    try {
      const response = await axios.post(`${BASE_URL}/api/create/region`, {
        regionName,
        regionCode,
      });

      if (response.data.message) {
        setRegionName("");
        setRegionCode("");
        handleClose();
        fetchRegions();
        toast.success("Region added Sucessfully");
      } else if (response.data.error) {
        setValidationError("Error: " + response.data.error);
      }
    } catch (error) {
      handleClose();
      setRegionName("");
      setRegionCode("");
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Region</p>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <p>Region Code</p>
            <Form.Control
              type="text"
              placeholder="Enter Region Code"
              value={regionCode}
              onChange={(e) => setRegionCode(e.target.value)}
            />
            <p className="mt-2">Region Name</p>
            <Form.Control
              type="text"
              placeholder="Enter Region Name"
              value={regionName}
              onChange={(e) => setRegionName(e.target.value)}
            />
            <h6 className="text-danger">{validationError}</h6>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-danger btn"
            style={{ color: "white" }}
            onClick={handleClose}
          >
            Close
          </button>
          <button className="btn btn-addCredential" onClick={handleAddRegion}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* Render ToastContainer */}
      <ToastContainer position="top-center" />
    </>
  );
};

export default AddRegionModal;
