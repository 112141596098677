import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { BASE_URL } from "../env";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { adminLoginValidation } from "../components/Validations";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./AdminDashboard.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";

export default function LoginForm() {
  const navigate = useNavigate();
  const { adminLogin } = useAdminAuth();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: adminLoginValidation,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/superadmin/login`,
          values
        );

        if (response.status === 200) { 
          toast.success(response.data.message);
          
          adminLogin(response.data);
          formik.resetForm();
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Login Failed");
      }
    },
  });

  return (
    <div className="d-flex justify-content-center formMain">
      <img
        className="formMainImg"
        src={require("../assets/loginBack.jpeg")}
        alt="Background"
      />
      <form className="formForm" onSubmit={formik.handleSubmit}>
        {/* <img src={require('../assets/logo.png')} width={'20%'}/>
        <h5 style={{fontWeight:'700',fontSize:15,marginTop:10,lineHeight:1.25}}>Login to Vetri Vikas Capital Trust</h5> */}
        <div className="formMainDiv">
          <div class="LoginHeaderMain">
            <h4 class="LoginHeaderText">Login in</h4>
            <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3 css-dp2bsp">
              <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-tflyol"></div>
            </div>
          </div>
          <div style={{padding:30}}>
            <h4 className="formH1">Hello Admin !</h4>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid black",
                margin: 10,
              }}
            >
              <FaUser />
              <input
                className="formInput"
                type="text"
                placeholder="Username"
                required
                name="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
              />
            </div>
            {formik.touched.username && formik.errors.username && (
              <span className="formError">{formik.errors.username}</span>
            )}

            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid black",
                margin: 10,
              }}
            >
              <RiLockPasswordFill />
              <input
                className="formInput"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <div
                onClick={togglePasswordVisibility}
                style={{ position: "absolute", right: 10, cursor: "pointer" }}
              >
                {showPassword ? (
                  <AiFillEyeInvisible size={20} />
                ) : (
                  <AiFillEye size={20} />
                )}
              </div>
            </div>

            {formik.touched.password && formik.errors.password && (
              <span className="formError">{formik.errors.password}</span>
            )}

            <button className="formButton btn btn-primary" type="submit">
              Login
            </button>
          </div>
        </div>
      </form>
      <ToastContainer position="top-center" />
    </div>
  );
}
