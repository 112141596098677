import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import { BASE_URL } from "../env";
import axios from "axios";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { saveAs } from "file-saver";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ApprovedMemberDetailsModal = ({ show, handleClose, selectedMember }) => {
  const [photoPaths, setPhotoPaths] = useState({});
  const navigate = useNavigate();
  const [nomineePto, setNomineePto] = useState({});
  const [bankDocument, setBankDocument] = useState([]);
  const [incomeDocuments, setIncomeDocuments] = useState([]);
  const [incomePtos, setIncomePtos] = useState([]);
  const [creditDocument, setCreditDocument] = useState([]);
  const [bankTransactionDetails, setBankTransactionDetails] = useState({});
  const [videoPath, setVideoPath] = useState(null);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [creditPtos, setCreditPtos] = useState([]);
  const [creditVideo, setCreditVideo] = useState(null);

  const fetchData = async () => {
    try {
      if (show && selectedMember) {
        await fetchApprovedDocuments();
        await fetchNomineePhotos();
        await fetchBankPdf();
        await fetchIncomeDocuments();
        await fetchIncomePhotos();
        await fetchCreaditDocuments();
        await fetchBankTransactionDetails();
        await fetchMemberVideo();
        await fetchOtherDocuments();
        await fetchCreditManagerPhotos();
      }
    } catch (error) {
      console.error("Error in fetchData:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [show, selectedMember]);

  const resetPhotoStates = () => {
    setPhotoPaths({});
    setNomineePto({});
    setBankDocument([]);
    setIncomeDocuments([]);
    setCreditDocument([]);
    setIncomePtos([]);
    setBankTransactionDetails({});
    setVideoPath({});
    setOtherDocuments([]);
    handleClose();
  };

  const fetchApprovedDocuments = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/fetch-image-path/${selectedMember?.memberDetails.memberId}`
      );
      setPhotoPaths(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  const fetchNomineePhotos = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/nomineephotos/${selectedMember?.memberDetails.memberId}`
      );
      setNomineePto(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  const fetchIncomePhotos = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/incomeproof/${selectedMember?.memberDetails.memberId}`
      );
      setIncomePtos(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  const fetchBankPdf = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/bankdocument/${selectedMember?.memberDetails.memberId}`
      );
      setBankDocument(response.data);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  const fetchIncomeDocuments = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/incomedocument/${selectedMember?.memberDetails.memberId}`
      );
      setIncomeDocuments(response.data);
    } catch (error) {
      console.error("Error fetching income documentsssss:", error);
    }
  };

  const fetchCreaditDocuments = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/creditdocument/${selectedMember?.memberDetails.memberId}`
      );
      setCreditDocument(response.data);
    } catch (error) {
      console.error("Error fetching income documentsssss:", error);
    }
  };
  const fetchCreditManagerPhotos = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/creditmanager/documents/${selectedMember?.memberDetails.memberId}`
      );
      setCreditPtos(response.data);
      setCreditVideo(response.data.memberVideo);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  const fetchBankTransactionDetails = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/bank/transaction/${selectedMember?.memberDetails.memberId}`
      );
      setBankTransactionDetails(response.data);
    } catch (error) {
      console.error("Error fetching bank transaction details:", error);
    }
  };
  const fetchMemberVideo = async () => {
    try {
      setVideoPath(null);
      const response = await axios.get(
        `${BASE_URL}/membervideo/${selectedMember?.memberDetails.memberId}`
      );
      const { video_name } = response.data[0];

      setVideoPath(video_name);
    } catch (error) {
      console.error("Error fetching member video names:", error);
    }
  };

  const fetchOtherDocuments = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/field/manager/otherdocuments/${selectedMember?.memberDetails.memberId}`
      );
      setOtherDocuments(response.data);
    } catch (error) {
      console.error("Error fetching other document:", error);
    }
  };

  const openPdfInNewTab = (pdfName) => {
    const url = `${BASE_URL}/images/${pdfName}`;
    window.open(url, "_blank");
  };

  const downloadFile = (fileName) => {
    axios
      .get(`${BASE_URL}/images/${fileName}`, {
        responseType: "blob",
      })
      .then((response) => {
        saveAs(response.data, fileName);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const deleteMemberPhoto = async (photoType) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this photo?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/member/photos`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            photoType: photoType,
          },
        }
      );
      console.log(response.data);
      await fetchApprovedDocuments();
    } catch (error) {
      console.error("Error deleting member photo:", error);
    }
  };

  const deleteCreaditUploadedPhoto = async (photoType) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this photo?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/credit/photos`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            photoType: photoType,
          },
        }
      );
      console.log(response.data);
      await fetchCreditManagerPhotos();
    } catch (error) {
      console.error("Error deleting member photo:", error);
    }
  };

  const deleteNomineePhoto = async (photoType) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this photo?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/nominee/photos`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            photoType: photoType,
          },
        }
      );
      console.log(response.data);
      await fetchNomineePhotos();
    } catch (error) {
      console.error("Error deleting nominee photo:", error);
    }
  };

  const deleteIncomeProofPhoto = async (photoType) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this photo?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/incomeproof/photos`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            photoType: photoType,
          },
        }
      );
      console.log(response.data);
      await fetchIncomePhotos();
    } catch (error) {
      console.error("Error deleting income proof photo:", error);
    }
  };

  const deleteBankPhoto = async (photoType) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this photo ?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/bank/photos`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            screenshotType: photoType,
          },
        }
      );
      console.log(response.data);
      await fetchBankTransactionDetails();
    } catch (error) {
      console.error("Error deleting bank transaction photo:", error);
    }
  };

  const deleteBankDocument = async (document_name) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Document ?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/bankdocument`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            document_name: document_name,
          },
        }
      );
      console.log(response.data);
      await fetchBankPdf();
    } catch (error) {
      console.error("Error deleting bank document:", error);
    }
  };

  const deleteIncomeDocument = async (document_name) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Document ?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/incomedocument`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            document_name: document_name,
          },
        }
      );
      console.log(response.data);
      await fetchIncomeDocuments();
    } catch (error) {
      console.error("Error deleting income document:", error);
    }
  };

  const deleteCreditDocument = async (document_name) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Document ?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/creditdocument`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            document_name: document_name,
          },
        }
      );
      console.log(response.data);
      await fetchCreaditDocuments();
    } catch (error) {
      console.error("Error deleting credit document:", error);
    }
  };

  const deleteOtherDocuments = async (document_name) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Document ?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/delete/otherdocument`,
        {
          data: {
            member_id: selectedMember?.memberDetails.memberId,
            document_name: document_name,
          },
        }
      );
      console.log(response.data);
      await fetchOtherDocuments();
    } catch (error) {
      console.error("Error deleting income document:", error);
    }
  };
  const handlePrintClick = () => {
    navigate("/memberdetail/print", {
      state: {
        selectedMember,
        photoPaths,
        nomineePto,
        incomePtos,
        creditPtos,
        bankTransactionDetails,
      },
    });
  };

  return (
    <Modal
      show={show}
      onHide={resetPhotoStates}
      dialogClassName="full-width-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center">
          <button className="btn btn-success mx-2" onClick={handlePrintClick}>
            View Document
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-4">
          <p className="modalheadingmembers text-danger">
            PART-1 Family Details
          </p>
          <div className="table-responsive table-responsive-custom">
            <div className="table-responsive table-responsive-custom">
              <table className="table table-bordered table-bordered-custom">
                <tbody>
                  {[
                    ["Particulars", "KUDUMBA THALAIVI"],
                    ["Member Name", selectedMember?.memberDetails.memberName],
                    ["Phone Number", selectedMember?.memberDetails.phoneNumber],
                    [
                      "Date of Birth",
                      selectedMember?.memberDetails.dateOfBirth,
                    ],
                    ["Age", selectedMember?.memberDetails.age],
                    [
                      "Residence Type",
                      selectedMember?.memberDetails.residenceType,
                    ],
                    ["Aadhar No", selectedMember?.memberDetails.aadharNo],
                    ["PAN ID", selectedMember?.memberDetails.panId],
                    ["Voter ID", selectedMember?.memberDetails.voterId],
                    ["EB Number", selectedMember?.memberDetails.ebNumber],
                    [
                      "Family Card No",
                      selectedMember?.memberDetails.familyCardNo,
                    ],
                    [
                      "Ration Shop No",
                      selectedMember?.memberDetails.rationShopNo,
                    ],
                    [
                      "Ration Shop Name",
                      selectedMember?.memberDetails.rationShopName,
                    ],
                    ["Door No", selectedMember?.memberDetails.doorNo],
                    ["Street No", selectedMember?.memberDetails.streetNo],
                    ["Panchayat", selectedMember?.memberDetails.panchayat],
                    ["Taluk", selectedMember?.memberDetails.taluk],
                    ["District", selectedMember?.memberDetails.district],
                    ["Pincode", selectedMember?.memberDetails.pincode],
                  ].map(([field, memberValue]) => (
                    <tr key={field}>
                      <td>
                        {field === "Particulars" ? (
                          <strong>Profile</strong>
                        ) : (
                          "Profile"
                        )}
                      </td>
                      <td>
                        {field === "Particulars" ? (
                          <strong>{field}</strong>
                        ) : (
                          field
                        )}
                      </td>
                      <td>
                        {field === "Particulars" ? (
                          <strong>{memberValue}</strong>
                        ) : (
                          memberValue
                        )}
                      </td>
                      {selectedMember &&
                        selectedMember.familyDetails.map(
                          (familyMember, index) => (
                            <td key={index}>
                              {field === "Particulars" ? (
                                <strong>{familyMember.relationshipType}</strong>
                              ) : field === "Member Name" ? (
                                familyMember.memberName
                              ) : field === "Phone Number" ? (
                                familyMember.phoneNumber
                              ) : field === "Date of Birth" ? (
                                familyMember.dateOfBirth
                              ) : field === "Age" ? (
                                familyMember.age
                              ) : field === "Residence Type" ? (
                                familyMember.residenceType
                              ) : field === "Aadhar No" ? (
                                familyMember.aadharNo
                              ) : field === "PAN ID" ? (
                                familyMember.panId
                              ) : field === "Voter ID" ? (
                                familyMember.voterId
                              ) : field === "EB Number" ? (
                                familyMember.ebNumber
                              ) : field === "Family Card No" ? (
                                familyMember.familyCardNo
                              ) : field === "Ration Shop No" ? (
                                familyMember.rationShopNo
                              ) : field === "Ration Shop Name" ? (
                                familyMember.rationShopName
                              ) : field === "Door No" ? (
                                familyMember.doorNo
                              ) : field === "Street No" ? (
                                familyMember.streetNo
                              ) : field === "Panchayat" ? (
                                familyMember.panchayat
                              ) : field === "Taluk" ? (
                                familyMember.taluk
                              ) : field === "District" ? (
                                familyMember.district
                              ) : field === "Pincode" ? (
                                familyMember.pincode
                              ) : (
                                ""
                              )}
                            </td>
                          )
                        )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Nominee details */}
            {selectedMember?.nomineeDetails.length > 0 && (
              <>
                <p className="modalheadingmembers text-danger">
                  Nominee Details
                </p>
                <div className="table-responsive table-responsive-custom">
                  <table className="table table-bordered table-bordered-custom">
                    <thead>
                      <tr>
                        <th>Nominee Relation Type</th>
                        <th>Nominee Name</th>
                        <th>Phone Number</th>
                        <th>Date of Birth</th>
                        <th>Age</th>
                        <th>Aadhar No</th>
                        <th>PAN or Voter ID</th>
                        <th>Door No</th>
                        <th>Street No</th>
                        <th>Panchayat</th>
                        <th>Taluk</th>
                        <th>District</th>
                        <th>Pincode</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedMember.nomineeDetails.map((nominee, index) => (
                        <tr key={index}>
                          <td>{nominee.nomineeRelationType}</td>
                          <td>{nominee.nomineeName}</td>
                          <td>{nominee.phoneNumber}</td>

                          <td>{nominee.dateOfBirth}</td>
                          <td>{nominee.age}</td>
                          <td>{nominee.aadharNo}</td>
                          <td>{nominee.panOrVoterId}</td>

                          <td>{nominee.doorNo}</td>
                          <td>{nominee.streetNo}</td>
                          <td>{nominee.panchayat}</td>
                          <td>{nominee.taluk}</td>
                          <td>{nominee.district}</td>
                          <td>{nominee.pincode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {/* Business Details */}
            <p className="modalheadingmembers text-danger">
              PART-2 KUDUMBA THOLIL VIVARANGAL
            </p>
            <div className="table-responsive table-responsive-custom">
              <div className="table-responsive table-responsive-custom">
                <table className="table table-bordered table-bordered-custom">
                  <thead>
                    <tr>
                      <th>Business Profile</th>
                      <th>RelationShip Type</th>
                      {selectedMember?.businessDetails.map(
                        (businessDetail, index) => (
                          <th key={index}>{businessDetail.relationshipType}</th>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Business Profile</td>
                      <td>Job/self</td>
                      {selectedMember?.businessDetails.map(
                        (businessDetail, index) => (
                          <td key={index}>{businessDetail.sourceOfIncome}</td>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Business Profile</td>
                      <td>Company</td>
                      {selectedMember?.businessDetails.map(
                        (businessDetail, index) => (
                          <td key={index}>{businessDetail.companyName}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Business Profile</td>
                      <td>Job / Business Name</td>
                      {selectedMember?.businessDetails.map(
                        (businessDetail, index) => (
                          <td key={index}>{businessDetail.businessType}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Business Profile</td>
                      <td>Salary</td>
                      {selectedMember?.businessDetails.map(
                        (businessDetail, index) => (
                          <td key={index}>{businessDetail.amount}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Business Profile</td>
                      <td>Bank</td>
                      {selectedMember?.businessDetails.map(
                        (businessDetail, index) => (
                          <td key={index}>{businessDetail.bankName}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Business Profile</td>
                      <td>IFSC</td>
                      {selectedMember?.businessDetails.map(
                        (businessDetail, index) => (
                          <td key={index}>{businessDetail.ifscCode}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Business Profile</td>
                      <td>Acc No</td>
                      {selectedMember?.businessDetails.map(
                        (businessDetail, index) => (
                          <td key={index}>{businessDetail.accountNo}</td>
                        )
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/*Loan Details */}
            <p className="modalheadingmembers text-danger">
              PART-3 KUDUMBA KADAN VIVARANGAL
            </p>
            <div className="table-responsive table-responsive-custom">
              <div className="table-responsive table-responsive-custom">
                <table className="table table-bordered table-bordered-custom">
                  <thead>
                    <tr>
                      <th>Present Loan Details</th>
                      <th>RelationShip Type</th>
                      {selectedMember?.loanDetails.map((loanDetail, index) => (
                        <th key={index}>{loanDetail.relationshipType}</th>
                      ))}
                    </tr>
                    <tr>
                      <td>Loan Profile</td>
                      <td>Company</td>
                      {selectedMember?.loanDetails.map((loanDetail, index) => (
                        <td key={index}>{loanDetail.loanCompanyName}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Loan Profile</td>
                      <td>LA</td>
                      {selectedMember?.loanDetails.map((loanDetail, index) => (
                        <td key={index}>{loanDetail.loanAmount}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Loan Profile</td>
                      <td>EMI</td>
                      {selectedMember?.loanDetails.map((loanDetail, index) => (
                        <td key={index}>{loanDetail.monthlyEmi}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/*Proposed Loan Details */}

            {/* Proposed Loan Details */}
            <p className="modalheadingmembers text-danger">
              PART-4 Proposed Loan Details with GT
            </p>
            <div className="table-responsive table-responsive-custom">
              {selectedMember?.proposedLoanDetails &&
              selectedMember.proposedLoanDetails.length > 0 ? (
                <table className="table table-bordered table-bordered-custom w-50">
                  <tbody>
                    <tr>
                      <td>Applicant / Borrower</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedApplicantName}
                          </td>
                        )
                      )}
                    </tr>

                    <tr>
                      <td>Co-Applicant /Co-Borrower</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedCoApplicant1}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Guarantor</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedCoApplicant2}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>LA</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedLoanAmount}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Monthly EMI</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedMonthlyEmi}
                          </td>
                        )
                      )}
                    </tr>

                    <tr>
                      <td>EMI Start Date</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedEmiStartDate}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>EMI End Date</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedEmiEndDate}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Bank Name</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedBankName}
                          </td>
                        )
                      )}
                    </tr>

                    <tr>
                      <td>IFSC Code</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedIfscCode}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Ac No</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedAccountNumber}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td>Bank Ac linked Mobile</td>
                      {selectedMember.proposedLoanDetails.map(
                        (proposedLoanDetail, index) => (
                          <td key={index}>
                            {proposedLoanDetail.proposedLinkedMobileNumber}
                          </td>
                        )
                      )}
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div className="no-data-message">
                  No proposed loan details available.
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Credit uploaded ptos */}
        {/* Credit uploaded ptos */}
        <p className="modalheadingmembers text-danger">
          PART-5 FI/CREDIT UNDERSWRITING
        </p>
        <div className="d-flex flex-column ">
          {Object.entries(creditPtos).map(
            ([key, value]) =>
              key !== "memberVideo" && (
                <div key={key} className="px-3 member-photo">
                  <div className="d-flex justify-content-between">
                    <p className="mt-2 text-primary">{key}</p>
                    <div>
                      <button
                        className="btn btn-primary my-2"
                        onClick={() => downloadFile(value)}
                      >
                        Download
                      </button>
                      <button
                        className="btn btn-danger mx-2 my-2"
                        onClick={() => deleteCreaditUploadedPhoto(key)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <Zoom>
                    <img
                      src={`${BASE_URL}/images/${value}`}
                      alt={key}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        backgroundColor: "#f3f4f9",
                      }}
                    />
                  </Zoom>
                </div>
              )
          )}
        </div>
        <div className="d-flex justify-content-center my-4">
          {creditVideo === null ? (
            <p>credit Video Not Found...</p>
          ) : (
            <video controls className="w-50" style={{ height: "500px" }}>
              <source
                src={`${BASE_URL}/images/${creditVideo}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        {/* Credit uploaded ptos */}

        <div className="mt-4">
          <p className="modalheadingmembers text-danger">
            {" "}
            Customer Relationship Officer Details
          </p>
          <div className="d-flex">
            <strong>Username:</strong>
            <p className="px-2">
              {selectedMember?.fieldManagerDetails.username}
            </p>
          </div>

          <div className="d-flex">
            <strong>Branch Name:</strong>
            <p className="px-2">
              {selectedMember?.fieldManagerDetails.branchName}
            </p>
          </div>
        </div>
        <div>
          <p className="modalheadingmembers text-danger">Member Photos</p>

          <div className="d-flex flex-column ">
            {Object.entries(photoPaths).map(([key, value]) => (
              <div key={key} className="px-3 member-photo">
                <div className="d-flex justify-content-between">
                  <p className="mt-2 text-primary">{key}</p>
                  <div>
                    <button
                      className="btn btn-primary my-2"
                      onClick={() => downloadFile(value)}
                    >
                      Download
                    </button>
                    <button
                      className="btn btn-danger mx-2 my-2"
                      onClick={() => deleteMemberPhoto(key)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <Zoom>
                  <img
                    src={`${BASE_URL}/images/${value}`}
                    alt={key}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      backgroundColor: "#f3f4f9",
                    }}
                  />
                </Zoom>
              </div>
            ))}
          </div>
          <div>
            <p className="modalheadingmembers text-danger"> Nominee Photos</p>

            <div className="d-flex flex-column">
              {Object.entries(nomineePto).map(([key, value]) => (
                <div key={key} className="px-3 member-photo">
                  <div className="d-flex justify-content-between">
                    <p className="mt-2 text-primary">{key}</p>
                    <div className="d-flex justify-content-between">
                      <button
                        className="btn btn-primary my-2"
                        onClick={() => downloadFile(value)}
                      >
                        Download
                      </button>
                      <button
                        className="btn btn-danger mx-2 my-2"
                        onClick={() => deleteNomineePhoto(key)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <Zoom>
                    <img
                      src={`${BASE_URL}/images/${value}`}
                      alt={key}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        backgroundColor: "#f3f4f9",
                      }}
                    />
                  </Zoom>
                </div>
              ))}

              <div>
                <p className="modalheadingmembers text-danger">
                  Income Proof Photos
                </p>

                {Object.entries(incomePtos).map(([key, value]) => (
                  <div key={key} className="px-3 member-photo">
                    <div className="d-flex justify-content-between">
                      <p className="mt-2 text-primary">{key}</p>
                      <div>
                        <button
                          className="btn btn-primary my-2"
                          onClick={() => downloadFile(value)}
                        >
                          Download
                        </button>
                        <button
                          className="btn btn-danger mx-2 my-2"
                          onClick={() => deleteIncomeProofPhoto(key)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <Zoom>
                      <img
                        src={`${BASE_URL}/images/${value}`}
                        alt={key}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          backgroundColor: "#f3f4f9",
                        }}
                      />
                    </Zoom>
                  </div>
                ))}
              </div>
            </div>
            <p className="modalheadingmembers text-danger">Bank Documents</p>

            <div className="d-flex flex-column">
              {bankDocument.length > 0 ? (
                bankDocument.map((doc, index) => (
                  <div
                    key={index}
                    className="px-3"
                    onClick={() => openPdfInNewTab(doc.document_name)}
                  >
                    <div
                      className="d-flex justify-content-between"
                      style={{ cursor: "pointer" }}
                    >
                      <p>{doc.document_name}</p>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={() => downloadFile(doc.document_name)}
                        >
                          Download
                        </button>
                        <button
                          className="btn btn-danger mx-2 my-2"
                          onClick={() => deleteBankDocument(doc.document_name)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No bank documents available</p>
              )}
            </div>

            <div>
              <p className="modalheadingmembers text-danger">
                {" "}
                Bank Transaction Details
              </p>

              <div>
                {Object.entries(bankTransactionDetails).map(
                  ([key, value]) =>
                    key !== "memberId" && (
                      <div key={key} className="px-3 member-photo">
                        <div className="d-flex justify-content-between">
                          <p className="mt-2 text-primary">{key}</p>
                          <div>
                            <button
                              className="btn btn-primary my-2"
                              onClick={() => downloadFile(value)}
                            >
                              Download
                            </button>
                            <button
                              className="btn btn-danger mx-2 my-2"
                              onClick={() => deleteBankPhoto(key)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                        <Zoom>
                          <img
                            src={`${BASE_URL}/images/${value}`}
                            alt={key}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              backgroundColor: "#f3f4f9",
                            }}
                          />
                        </Zoom>
                      </div>
                    )
                )}
              </div>

              {/* bank transaction */}
            </div>

            <div>
              <div className="d-flex flex-column">
                <p className="modalheadingmembers text-danger">
                  Income Documents
                </p>

                {incomeDocuments.length > 0 ? (
                  incomeDocuments.map((doc, index) => (
                    <div
                      key={index}
                      className="px-3"
                      onClick={() => openPdfInNewTab(doc.document_name)}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ cursor: "pointer" }}
                      >
                        <p>{doc.document_name}</p>
                        <div>
                          <button
                            className="btn btn-primary"
                            onClick={() => downloadFile(doc.document_name)}
                          >
                            Download
                          </button>
                          <button
                            className="btn btn-danger mx-2 my-2"
                            onClick={() =>
                              deleteIncomeDocument(doc.document_name)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No income documents available</p>
                )}
              </div>
            </div>
            <div className="d-flex flex-column">
              <p className="modalheadingmembers text-danger">
                Credit Documents
              </p>

              {creditDocument.length > 0 ? (
                creditDocument.map((doc, index) => (
                  <div
                    key={index}
                    className="px-3"
                    onClick={() => openPdfInNewTab(doc.document_name)}
                  >
                    <div
                      className="d-flex justify-content-between"
                      style={{ cursor: "pointer" }}
                    >
                      <p>{doc.document_name}</p>
                      <div>
                        <button
                          className="btn btn-primary my-2"
                          onClick={() => downloadFile(doc.document_name)}
                        >
                          Download
                        </button>
                        <button
                          className="btn btn-danger mx-2 my-2"
                          onClick={() =>
                            deleteCreditDocument(doc.document_name)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Credit documents available</p>
              )}
            </div>
            <div>
              <div>
                {Object.entries(otherDocuments).map(
                  ([key, value], index) =>
                    key !== "memberId" && (
                      <div key={key} className="px-3 member-photo">
                        <div className="d-flex justify-content-between">
                          <p>Other Document {index + 1}</p>
                          <div>
                            <button
                              className="btn btn-primary my-2"
                              onClick={() => downloadFile(value.documentName)}
                            >
                              Download
                            </button>
                            <button
                              className="btn btn-danger mx-2 my-2"
                              onClick={() =>
                                deleteOtherDocuments(value.documentName)
                              }
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                        <Zoom>
                          <img
                            src={`${BASE_URL}/images/${value.documentName}`}
                            alt={key}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              backgroundColor: "#f3f4f9",
                            }}
                          />
                        </Zoom>
                      </div>
                    )
                )}
              </div>

              {/* bank transaction */}
            </div>
            <div className="d-flex justify-content-center">
              {videoPath === null ? (
                <p>Loading video...</p>
              ) : (
                <video controls className="w-50" style={{ height: "500px" }}>
                  <source
                    src={`${BASE_URL}/images/${videoPath}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="bg-danger btn"
          style={{ color: "white" }}
          onClick={resetPhotoStates}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovedMemberDetailsModal;
