import React from "react";
import { Table, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const DataTable = ({ columns, data, reportName, excelData }) => {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      excelData.map((rowData, rowIndex) =>
        columns.reduce((acc, column) => {
          acc[column.label] = column.render(rowData, rowIndex);
          return acc;
        }, {})
      )
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    // Get current date and time in ddmmyyyy_hhmm format
    const now = new Date();
    const formattedDate = `${now.getDate().toString().padStart(2, "0")}${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${now.getFullYear()}`;
    const formattedTime = `${now.getHours().toString().padStart(2, "0")}${now
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    const fileName = `${reportName}_${formattedDate}_${formattedTime}.xlsx`;

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  };

  return (
    <>
      <Button onClick={exportToExcel} className="mb-3">
        Download as Excel
      </Button>
      <Table responsive>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{column.render(rowData, rowIndex)}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>No Data Available</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default DataTable;
