import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../env";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const AddBranchModal = ({ show, handleClose, fetchBranches }) => {
  const [branchCode, setBranchCode] = useState("");
  const [branchName, setBranchName] = useState("");
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [validationError, setValidationError] = useState("");

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/divisions`);

      setDivisions(response.data);
    } catch (error) {
      console.error("Error fetching divisions:", error);
    }
  };

  useEffect(() => {
    fetchDivisions();
  }, []);

  const handleChangeDivision = (selectedOption) => {
    setSelectedDivision(selectedOption);
  };

  const handleAddBranch = async () => {
    if (!branchCode || !branchName || !selectedDivision) {
      setValidationError("Please Enter All Fields");
      return;
    }
    try {
      const response = await axios.post(`${BASE_URL}/api/create/branch`, {
        branchName,
        branchCode,
        divisionId: selectedDivision.value,
      });

      if (response.data.message) {
        setBranchName("");
        setBranchCode("");
        setSelectedDivision(null);
        handleClose();
        fetchBranches();
        toast.success("Branch added Sucessfully");
      } else if (response.data.error) {
        setValidationError("Error: " + response.data.error);
      }
    } catch (error) {
      handleClose();
      setBranchName("");
      setBranchCode("");
      setSelectedDivision(null);
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Branch</p>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <p>Division</p>
            <Select
              value={selectedDivision}
              onChange={handleChangeDivision}
              options={divisions.map((division) => ({
                value: division.id,
                label: division.divisionName,
              }))}
              isClearable
              placeholder="---Select Division---"
              className="w-100"
            />
            <p>Branch Code</p>
            <Form.Control
              type="text"
              placeholder="Enter Branch Code"
              value={branchCode}
              onChange={(e) => setBranchCode(e.target.value)}
            />
            <p className="mt-2">Branch Name</p>
            <Form.Control
              type="text"
              placeholder="Enter Branch Name"
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
            />
            <h6 className="text-danger">{validationError}</h6>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-danger btn"
            style={{ color: "white" }}
            onClick={handleClose}
          >
            Close
          </button>
          <button className="btn btn-addCredential" onClick={handleAddBranch}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* Render ToastContainer */}
      <ToastContainer position="top-center" />
    </>
  );
};

export default AddBranchModal;
