import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useAdminAuth } from "./components/AdminAuthProvider";

const PrivateRoute = () => {
  const { adminUser } = useAdminAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminUser) {
      navigate("/");
    }
  }, [adminUser, navigate]);

  return (
    adminUser && (
      <>
        <Outlet />
      </>
    )
  );
};

export default PrivateRoute;
