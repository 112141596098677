import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import AdminAddLoanTenureModal from "../components/AdminAddLoanTenureModal";

const LoanTenureList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredLoanTenures, setFilteredLoanTenures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loanTenuresPerPage] = useState(20);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedLoanTenure, setSelectedLoanTenure] = useState(null);

  useEffect(() => {
    fetchLoanTenures();
  }, []);

  const fetchLoanTenures = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/fetchLoanTenureWithFrequency`
      );
      setFilteredLoanTenures(response.data.loanTenuresWithFrequency);
    } catch (error) {
      console.error("Error fetching loan tenures:", error);
    }
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleAddLoanTenureClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchLoanTenures();
  };

  const handleEditLoanTenure = (loanTenure) => {
    setSelectedLoanTenure(loanTenure);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedLoanTenure(null);
  };

  const handleSaveChanges = async () => {
    if (selectedLoanTenure) {
      try {
        const response = await axios.put(`${BASE_URL}/admin/editLoanTenure`, {
          loanTenureId: selectedLoanTenure.tenureId,
          loanTenure: selectedLoanTenure.loanTenure,
        });
        if (response.status === 200) {
          fetchLoanTenures();
          handleCloseEditModal();
        } else {
          console.error("Failed to update loan tenure");
        }
      } catch (error) {
        console.error("Error updating loan tenure:", error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTenures = filteredLoanTenures.filter((tenure) =>
    tenure.loanTenure.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastLoanTenure = currentPage * loanTenuresPerPage;
  const indexOfFirstLoanTenure = indexOfLastLoanTenure - loanTenuresPerPage;
  const currentLoanTenures = filteredTenures.slice(
    indexOfFirstLoanTenure,
    indexOfLastLoanTenure
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredTenures.length / loanTenuresPerPage);

  const renderLoanTenureList = () => (
    <div className="mt-4 text-center">
      <h5 className="pb-3">Loan Tenure List</h5>
      <div className="table-responsive">
        <table className="mx-auto table table-striped table-list">
          <thead>
            <tr>
              <th>ID</th>
              <th>Loan Frequency</th>
              <th>Loan Tenure</th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentLoanTenures.length > 0 ? (
              currentLoanTenures.map((loanTenure, index) => (
                <tr key={loanTenure.tenureId}>
                  <td>{indexOfFirstLoanTenure + index + 1}</td>
                  <td>{loanTenure.loanfrequency}</td>
                  <td>{loanTenure.loanTenure}</td>

                  <td>
                    <button
                      className="btn btn-edit"
                      title="Edit"
                      onClick={() => handleEditLoanTenure(loanTenure)}
                    >
                      <MdModeEdit />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No loan tenures found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination className="me-auto custom-pagination">
        <Pagination.First onClick={() => paginate(1)} />
        <Pagination.Prev
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => paginate(number)}
            className="active"
          >
            {number}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last onClick={() => paginate(totalPages)} />
      </Pagination>
    </div>
  );

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{ overflowY: "scroll", height: "100vh", scrollbarWidth: 'none' }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <div>
            <button
              className="btn btn-addCredential mt-3"
              onClick={handleAddLoanTenureClick}
            >
              Add Loan Tenure
            </button>
          </div>

          <div
            className="search-container"
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search by loan tenure"
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input"
              />
              <span className="search-icon">
                <IoSearch />
              </span>
            </div>
          </div>
          {renderLoanTenureList()}
        </div>
      </div>
      <AdminAddLoanTenureModal
        show={isModalOpen}
        handleClose={handleCloseModal}
      />
      {selectedLoanTenure && (
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Loan Tenure</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editLoanTenureName">
                <Form.Label className="pt-2">Loan Tenure</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedLoanTenure.loanTenure}
                  onChange={(e) =>
                    setSelectedLoanTenure({
                      ...selectedLoanTenure,
                      loanTenure: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default LoanTenureList;
