import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useAdminAuth } from "./components/AdminAuthProvider";

const SuperAdminPrivateRoute = () => {
  const { adminUser } = useAdminAuth();
  const isSuperAdmin = adminUser && adminUser.userType === "superadmin";
  const isDeveloper = adminUser && adminUser.userType === "developer";
  const navigate = useNavigate();

  useEffect(() => {
    if (!(isSuperAdmin || isDeveloper)) {
      navigate("/");
    }
  }, [isSuperAdmin, navigate, isDeveloper]);

  return (
    (isSuperAdmin || isDeveloper) && (
      <>
        <Outlet />
      </>
    )
  );
};

export default SuperAdminPrivateRoute;
