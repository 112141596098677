import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import AddFundingAgencyModal from "../components/AddFundingAgencyModal";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const FundingAgencyList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fundingAgencies, setFundingAgencies] = useState([]);
  const [filteredFundingAgencies, setFilteredFundingAgencies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fundingAgenciesPerPage] = useState(20);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedFundingAgency, setSelectedFundingAgency] = useState(null);

  useEffect(() => {
    fetchFundingAgencies();
  }, []);

  const fetchFundingAgencies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get/fundingagency`);
      setFundingAgencies(response.data.fundingAgencies);
      setFilteredFundingAgencies(response.data.fundingAgencies);
    } catch (error) {
      console.error("Error fetching funding agencies:", error);
    }
  };

  useEffect(() => {
    const filtered = fundingAgencies.filter((agency) =>
      agency.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredFundingAgencies(filtered);
    setCurrentPage(1);
  }, [searchQuery, fundingAgencies]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleAddFundingAgencyClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchFundingAgencies();
  };

  const handleEditFundingAgency = (agency) => {
    setSelectedFundingAgency(agency);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedFundingAgency(null);
  };

  const handleSaveChanges = async () => {
    if (selectedFundingAgency) {

      const newName = selectedFundingAgency.name.trim().toLowerCase();
      const nameExists = fundingAgencies.some(agency =>
        agency.name.trim().toLowerCase() === newName && agency.id !== selectedFundingAgency.id
      );

      if (nameExists) {
        toast.error("Funding agency name already exists.");
        return;
      }

      try {
        const response = await axios.put(`${BASE_URL}/admin/edit/fundingagency`, {
          fundingAgencyId: selectedFundingAgency.id,
          name: selectedFundingAgency.name,
        });
        if (response.status === 200) {
          fetchFundingAgencies();
          handleCloseEditModal();
        } else {
          console.error("Failed to update funding agency");
        }
      } catch (error) {
        console.error("Error updating funding agency:", error);
      }
    }
  };

  const indexOfLastFundingAgency = currentPage * fundingAgenciesPerPage;
  const indexOfFirstFundingAgency = indexOfLastFundingAgency - fundingAgenciesPerPage;
  const currentFundingAgencies = filteredFundingAgencies.slice(indexOfFirstFundingAgency, indexOfLastFundingAgency);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredFundingAgencies.length / fundingAgenciesPerPage);

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
        <div className="container-fluid" style={{ overflowY: "scroll", height: "100vh", scrollbarWidth: 'none' }}>
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <div>
            <button className="btn btn-addCredential mt-3" onClick={handleAddFundingAgencyClick}>
              Add Funding Agency
            </button>
          </div>
          <div className="search-container" style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginTop: "50px" }}>
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search by funding agency"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <span className="search-icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="mt-4 text-center">
            <h5 className="pb-3">Funding Agency List</h5>
            <div className="table-responsive">
              <table className="mx-auto table table-striped table-list">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Funding Agency Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentFundingAgencies.length > 0 ? (
                    currentFundingAgencies.map((agency, index) => (
                      <tr key={agency.id}>
                        <td>{index + indexOfFirstFundingAgency + 1}</td>
                        <td>{agency.name}</td>
                        <td>
                          <button className="btn btn-edit" title="Edit" onClick={() => handleEditFundingAgency(agency)}>
                            <MdModeEdit />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No funding agencies found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination className="custom-pagination">
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)} className="active">
                  {number}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
              <Pagination.Last onClick={() => paginate(totalPages)} />
            </Pagination>
          </div>
        </div>
      </div>
      <AddFundingAgencyModal show={isModalOpen} handleClose={handleCloseModal} />
      {selectedFundingAgency && (
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Funding Agency</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editFundingAgencyName">
                <Form.Label className="pt-2">Funding Agency Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedFundingAgency.name}
                  onChange={(e) => setSelectedFundingAgency({ ...selectedFundingAgency, name: e.target.value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default FundingAgencyList;
