import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";

const LoanScheduleList = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };



  return (
    <div className="dashboard-over">
    <DashboardHeader OpenSidebar={OpenSidebar} />
    <div className="d-flex">
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <div
        className="container"
        style={{ overflowY: "scroll", height: "85vh" }}
      >
        <div>
          <button
            className="btn btn-addCredential mt-3"
           
          >
            Add Loan Schedule List 
          </button>
        </div>

        <div
          className="search-container"
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <div className="search-input-wrapper">
            <input
              type="text"
              placeholder="Search by loan tenure"
           
              className="search-input"
            />
            <span className="search-icon">
              <IoSearch />
            </span>
          </div>
        </div>
        
      </div>
    </div>
 
  </div>
  )
}

export default LoanScheduleList