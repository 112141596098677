import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AddBusinessCategory from "./AddBusinessCategory";
import { BASE_URL } from "../env";
import Form from "react-bootstrap/Form";
import axios from "axios";



const AddNatureOfBusiness = () => {
  const [showModal, setShowModal] = useState(false);
  const [showBranchAddModal, setShowBranchAddModal] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectCategory, setSelectedCategory] = useState(null);
  const [businessNature, setBusinessNature] = useState("");



  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchCategory = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/business-categories`);

      setCategory(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const handleShowNestedModal = () => setShowBranchAddModal(true);
  const handleCloseNestedModal = () => setShowBranchAddModal(false);

  const handleChangeCategory = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };


  const handleAddBusiness = async () => {
    try {
      
      if (!selectCategory) {
        alert("Please select a business category.");
        return;
      }

      const businessData = {
        businessCategoryId: selectCategory.value, 
        natureOfBusiness: businessNature,
      };
       
      await axios.post(`${BASE_URL}/api/nature-of-business`, businessData);
      setBusinessNature("");
      setSelectedCategory(null);
      setShowModal(false);

    } catch (error) {
      console.error("Error adding business nature:", error);
      alert("Error in Adding Business Nature");
      setBusinessNature("");
      setSelectedCategory(null);
    }
  };
  

  return (
    <div className="">

      <button className="btn btn-addCredential mt-3" onClick={toggleModal}>Add Nature Of Business</button> 
         
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Nature of Business</p>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex jusify-content-center align-items-center ">
            <Select
              value={selectCategory}
              onChange={handleChangeCategory}
              options={category.map((item) => ({
                value:item.id,
                label: item.name,
              }))}
              isClearable
             
              onMenuOpen={fetchCategory}
              placeholder="---Select Business Category---"
              className="w-100"
            />
            <button className="m-4 btn btn-addCredential" onClick={handleShowNestedModal}>
              +
            </button>
          </div>
          <div className="d-flex jusify-content-center align-items-center">
         
          </div>
          <Form.Group controlId="username">
            <Form.Label className="pt-2">Nature of Business</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Business Name"
              value={businessNature}
              onChange={(e) => setBusinessNature(e.target.value)}
            />
          </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
          <button className="bg-danger btn" style={{color:'white'}} onClick={toggleModal}>
            Close
          </button>

          <button className="btn btn-addCredential" onClick={handleAddBusiness}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
      <AddBusinessCategory 
        show={showBranchAddModal}
        handleClose={handleCloseNestedModal}
      />
 
    </div>
  );
};

export default AddNatureOfBusiness;
