import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../env";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddFundingAgencyModal = ({ show, handleClose }) => {
  const [fundingAgency, setFundingAgency] = useState("");
  const [existingFundingAgencies, setExistingFundingAgencies] = useState([]);

  useEffect(() => {
    if (show) {
      fetchExistingFundingAgencies();
    }
  }, [show]);

  const fetchExistingFundingAgencies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get/fundingagency`);
      setExistingFundingAgencies(response.data.fundingAgencies);
    } catch (error) {
      toast.error("Failed to fetch existing funding agencies");
    }
  };

  const handleAddFundingAgency = async () => {
    const agencyExists = existingFundingAgencies.some(
      (agency) => agency.name === fundingAgency
    );

    if (agencyExists) {
      toast.error("Funding agency already exists");
      return;
    }

    try {
      await axios.post(`${BASE_URL}/add/fundingagency`, {
        name: fundingAgency,
      });
      setFundingAgency("");
      handleClose();
      toast.success("Funding Agency added successfully!");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <p className="add-martcategory-title">Add Funding Agency</p>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Enter Funding Agency"
            value={fundingAgency}
            onChange={(e) => setFundingAgency(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="bg-danger btn"
          style={{ color: "white" }}
          onClick={handleClose}
        >
          Close
        </button>
        <button
          className="btn btn-addCredential"
          onClick={handleAddFundingAgency}
        >
          Save
        </button>
      </Modal.Footer>
      <ToastContainer position="top-center" />
    </Modal>
  );
};

export default AddFundingAgencyModal;
