import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../env";
import Sidebar from "../components/Sidebar";
import { Row, Col, Form } from "react-bootstrap";
import {
  generateOutstandingAmountsForParMember,
  getFirstAndLastEmiDates,
} from "../components/utils";
import Select from "react-select";
import CustomPagination from "../components/CustomPagination";
import DataTable from "../components/DataTable";

const ParReport = () => {
  const [reportData, setReportData] = useState([]);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOverdueFilter, setSelectedOverdueFilter] = useState(null); // State for overdue days filter
  const [selectedBranchFilter, setSelectedBranchFilter] = useState(null); // State for branch name filter
  const [selectedEmployeeFilter, setSelectedEmployeeFilter] = useState(null); // State for employee name filter
  const [fromDateDisbursed, setFromDateDisbursed] = useState("");
  const [toDateDisbursed, setToDateDisbursed] = useState("");
  const fromDate = new Date("2024-01-01");
  const toDate = new Date();

  const itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const getReportData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/getOutstandingReportData`
        );
        if (response.data) {
          setReportData(response.data);
        } else {
          console.log("Error in fetching Data");
        }
      } catch (error) {
        console.log("Error fetching Data:", error);
      }
    };

    getReportData();
  }, []);

  // Flatten the data for rendering
  const flattenedData = reportData
    .map((data) =>
      generateOutstandingAmountsForParMember(data, fromDate, toDate)
    ) // First map over the data
    .filter((result) => result !== null);

  const totalPages = Math.ceil(flattenedData.length / itemsPerPage);

  // Define columns with label and render logic
  const columns = [
    { label: "S No", render: (data, index) => index + 1 },
    { label: "Region", render: (data) => data.regionName },
    { label: "Division", render: (data) => data.divisionName },
    { label: "Branch Name", render: (data) => data?.branchName ?? "" },
    { label: "Branch Code", render: (data) => data?.branchCode ?? "" },
    { label: "Customer Name", render: (data) => data?.memberName ?? "" },
    { label: "Customer ID", render: (data) => data?.customerId ?? "" },
    {
      label: "Address",
      render: (data) =>
        `${data.permanentDoorNo},${data.permanentStreetNo},${data.permanentPanchayat},${data.permanentTaluk},${data.permanentDistrict}-${data.permanentPincode}`,
    },
    { label: "Contact No", render: (data) => data?.phoneNumber ?? "" },
    { label: "Loan Type", render: (data) => (data ? "Business Loan" : "") },
    { label: "Loan Cycle", render: (data) => data?.loanCycle ?? "" },
    { label: "Loan Account No", render: (data) => data?.loanId ?? "" },
    {
      label: "Rate of Interest",
      render: (data) => data?.proposedLoanDetails.rateOfInterest ?? "",
    },
    {
      label: "Disbursement Date",
      render: (data) =>
        data?.branchManagerStatus === "disbursed" &&
        data?.branchManagerStatusUpdatedAt
          ? new Date(data.branchManagerStatusUpdatedAt).toLocaleDateString(
              "en-GB",
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            )
          : "",
    },
    {
      label: "Maturity Date",
      render: (data) => {
        const { lastEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return lastEmiDate ? lastEmiDate.toLocaleDateString() : "";
      },
    },
    {
      label: "Loan Amount",
      render: (data) =>
        data?.branchManagerStatus === "disbursed"
          ? data?.sanctionedLoanAmountBySanctionCommittee ?? ""
          : "",
    },
    { label: "Employee Name", render: (data) => data?.employeeName ?? "" },
    { label: "Prospect ID", render: (data) => data?.ApplicationId ?? "" },
    {
      label: "Total Outstanding Priciple",
      render: (data) => data?.totalOutstandingPrincipal ?? "",
    },
    // {
    //   label: "Total Outstanding Interest",
    //   render: (data) => data?.totalOutstandingInterest ?? "",
    // },
    {
      label: "DPD",
      render: (data) => data?.overdueDays ?? "",
    },
  ];

  // Extract unique branch names and employee names for dropdown options
  const branchOptions = [
    ...new Set(flattenedData.map((data) => data?.branchName)),
  ]
    .filter((name) => name)
    .map((name) => ({ value: name, label: name }));

  const employeeOptions = [
    ...new Set(flattenedData.map((data) => data?.username)),
  ]
    .filter((name) => name)
    .map((name) => ({ value: name, label: name }));

  // Overdue days filter options
  const overdueFilters = [
    { value: "0-30", label: "0 - 30 days" },
    { value: "31-60", label: "31 - 60 days" },
    { value: "61-90", label: "61 - 90 days" },
    { value: "91-180", label: "91 - 180 days" },
    { value: "180-365", label: "180 - 365 days" },
    { value: ">365", label: "> 365 days" },
  ];

  // Function to filter the data based on selected filters
  const filterData = (data) => {
    let filteredData = data;

    if (selectedOverdueFilter) {
      filteredData = filteredData.filter((item) => {
        const overdueDays = item.overdueDays ?? 0;
        switch (selectedOverdueFilter.value) {
          case "0-30":
            return overdueDays >= 0 && overdueDays <= 30;
          case "31-60":
            return overdueDays >= 31 && overdueDays <= 60;
          case "61-90":
            return overdueDays >= 61 && overdueDays <= 90;
          case "91-180":
            return overdueDays >= 91 && overdueDays <= 180;
          case "180-365":
            return overdueDays >= 180 && overdueDays <= 365;
          case ">365":
            return overdueDays > 365;
          default:
            return data;
        }
      });
    }

    if (fromDateDisbursed && toDateDisbursed) {
      filteredData = filteredData.filter((item) => {
        const updatedAt = new Date(item.branchManagerStatusUpdatedAt);
        return (
          updatedAt >= new Date(fromDateDisbursed) &&
          updatedAt <= new Date(toDateDisbursed)
        );
      });
    }

    if (selectedBranchFilter) {
      filteredData = filteredData.filter(
        (item) => item?.branchName === selectedBranchFilter.value
      );
    }

    if (selectedEmployeeFilter) {
      filteredData = filteredData.filter(
        (item) => item?.username === selectedEmployeeFilter.value
      );
    }

    return filteredData;
  };

  const filteredData = filterData(flattenedData).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <h1>PAR Report</h1>

          <Row>
            <Col xs={12} lg={4}>
              <Form.Label>Overdue Days</Form.Label>
              <Select
                value={selectedOverdueFilter}
                onChange={setSelectedOverdueFilter}
                options={overdueFilters}
                isClearable
                placeholder="Filter by Overdue Days"
                className="filter-dropdown"
              />
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>From Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  value={fromDateDisbursed}
                  onChange={(event) => setFromDateDisbursed(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  value={toDateDisbursed}
                  onChange={(event) => setToDateDisbursed(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Label>Branch</Form.Label>
              <Select
                value={selectedBranchFilter}
                onChange={setSelectedBranchFilter}
                options={branchOptions}
                isClearable
                placeholder="Filter by Branch Name"
                className="filter-dropdown"
              />
            </Col>
            <Col xs={12} lg={4}>
              <Form.Label>Employee</Form.Label>
              <Select
                value={selectedEmployeeFilter}
                onChange={setSelectedEmployeeFilter}
                options={employeeOptions}
                isClearable
                placeholder="Filter by Employee Name"
                className="filter-dropdown"
              />
            </Col>
          </Row>
          <DataTable
            columns={columns}
            data={filteredData}
            reportName="ParReport"
            excelData={filterData(flattenedData)}
          />
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            paginate={paginate}
          />
        </div>
      </div>
    </div>
  );
};

export default ParReport;
