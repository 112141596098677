import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../env";
import Sidebar from "../components/Sidebar";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import CustomPagination from "../components/CustomPagination";
import DataTable from "../components/DataTable";
import { getFirstAndLastEmiDates } from "../components/utils";

const ClientProspectReport = () => {
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedBranchFilter, setSelectedBranchFilter] = useState(null); // State for branch name filter
  const [selectedEmployeeFilter, setSelectedEmployeeFilter] = useState(null); // State for employee name filter

  const itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginationData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const getReportData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/getClientProspectReportData`
        );
        // console.log(
        //   "clientProspect response.data: " + JSON.stringify(response.data)
        // );
        if (response.data) {
          setReportData(response.data);
        } else {
          console.log("Error in fetching Data");
        }
      } catch (error) {
        console.log("Error fetching Data:", error);
      }
    };

    getReportData();
  }, []);

  // Extract unique branch names and employee names for dropdown options
  const branchOptions = [
    ...new Set(filteredData.map((data) => data?.branchName)),
  ]
    .filter((name) => name)
    .map((name) => ({ value: name, label: name }));

  const employeeOptions = [
    ...new Set(filteredData.map((data) => data?.username)),
  ]
    .filter((name) => name)
    .map((name) => ({ value: name, label: name }));

  // Automatically filter data when fromDate or toDate changes
  useEffect(() => {
    if (fromDate && toDate) {
      const filtered = reportData
        .filter((item) => {
          // Filter by Date
          const updatedAt = new Date(item.branchManagerStatusUpdatedAt);
          return (
            updatedAt >= new Date(fromDate) && updatedAt <= new Date(toDate)
          );
        })
        .filter((item) => {
          // Filter by Branch Name
          if (selectedBranchFilter && selectedBranchFilter.value) {
            return item.branchName === selectedBranchFilter.value;
          }
          return true; // Return all items if no branch is selected
        })
        .filter((item) => {
          // Filter by Employee Name
          if (selectedEmployeeFilter && selectedEmployeeFilter.value) {
            return item.username === selectedEmployeeFilter.value;
          }
          return true; // Return all items if no employee is selected
        });

      setFilteredData(filtered);
    } else {
      setFilteredData([]); // Show no data if dates are not selected
    }
  }, [
    fromDate,
    toDate,
    reportData,
    selectedBranchFilter,
    selectedEmployeeFilter,
  ]);

  // Define columns with label and render logic
  const columns = [
    { label: "S No", render: (data, index) => index + 1 },
    { label: "Region", render: (data) => data.regionName },
    { label: "Division", render: (data) => data.divisionName },
    { label: "Branch Name", render: (data) => data.branchName },
    { label: "Branch Code", render: (data) => data.branchCode },
    { label: "Customer Name", render: (data) => data.memberName },
    { label: "Customer ID", render: (data) => data.customerId },
    {
      label: "Address",
      render: (data) =>
        `${data.permanentDoorNo},${data.permanentStreetNo},${data.permanentPanchayat},${data.permanentTaluk},${data.permanentDistrict}-${data.permanentPincode}`,
    },
    { label: "Contact No", render: (data) => data.phoneNumber },
    { label: "Loan Type", render: (data) => "Business Loan" },
    { label: "Loan Cycle", render: (data) => data.loanCycle },
    { label: "Loan Account No", render: (data) => data.loanId },
    {
      label: "Rate of Interest",
      render: (data) => data.proposedLoanDetails.rateOfInterest,
    },
    {
      label: "Disbursement Date",
      render: (data) =>
        data.branchManagerStatus === "disbursed" &&
        data.branchManagerStatusUpdatedAt
          ? new Date(data.branchManagerStatusUpdatedAt).toLocaleDateString(
              "en-GB",
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            )
          : "",
    },
    {
      label: "Maturity Date",
      render: (data) => {
        const { lastEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return lastEmiDate ? lastEmiDate.toLocaleDateString() : "";
      },
    },
    {
      label: "Loan Amount",
      render: (data) =>
        data.sanctionedLoanAmountBySanctionCommittee
          ? data.sanctionedLoanAmountBySanctionCommittee
          : data.proposedLoanDetails.loanAmount,
    },
    {
      label: "Role",
      render: (data) => {
        if (data.fieldManagerStatus === "retrigger")
          return "Customer Relationship Officer";
        if (
          data.branchManagerStatus === "retrigger" ||
          data.branchManagerStatus === "rejected"
        )
          return "Branch Manager";
        if (
          data.creditOfficerStatus === "retrigger" ||
          data.creditOfficerStatus === "rejected"
        )
          return "Credit Officer";
        if (data.misStatus === "retrigger" || data.misStatus === "rejected")
          return "MIS";
        if (
          data.creditManagerStatus === "retrigger" ||
          data.creditManagerStatus === "rejected"
        )
          return "Credit Manager";
        if (
          data.sanctionCommitteeStatus === "retrigger" ||
          data.sanctionCommitteeStatus === "rejected"
        )
          return "Sanction Committee";

        if (data.fieldManagerStatus === "pending")
          return "Customer Relationship Officer";
        if (
          data.fieldManagerStatus === "submitted" &&
          data.branchManagerStatus === "pending"
        )
          return "Branch Manager";
        if (
          data.branchManagerStatus === "submitted" &&
          data.creditOfficerStatus === "pending"
        )
          return "Credit Officer";
        if (
          data.creditOfficerStatus === "submitted" &&
          data.misStatus === "pending"
        )
          return "MIS";
        if (
          data.misStatus === "submitted" &&
          data.creditManagerStatus === "pending"
        )
          return "Credit Manager";
        if (
          data.creditManagerStatus === "submitted" &&
          data.sanctionCommitteeStatus === "pending"
        )
          return "Sanction Committee";
        if (
          data.sanctionCommitteeStatus === "approved" &&
          data.branchManagerStatus === "submitted"
        )
          return "Branch Manager";
        if (
          data.branchManagerStatus === "disbursed" &&
          data.misStatus === "submitted"
        )
          return "MIS";
        if (
          data.misStatus === "disbursed" &&
          data.accountManagerStatus === "pending"
        )
          return "Account Manager";

        return "Unknown Role";
      },
    },
    {
      label: "Status",
      render: (data) => {
        if (
          data.fieldManagerStatus === "retrigger" ||
          data.branchManagerStatus === "retrigger" ||
          data.creditOfficerStatus === "retrigger" ||
          data.misStatus === "retrigger" ||
          data.creditManagerStatus === "retrigger" ||
          data.sanctionCommitteeStatus === "retrigger"
        )
          return "retrigger";
        if (
          data.branchManagerStatus === "rejected" ||
          data.creditOfficerStatus === "rejected" ||
          data.misStatus === "rejected" ||
          data.creditManagerStatus === "rejected" ||
          data.sanctionCommitteeStatus === "rejected"
        )
          return "rejected";
        if (
          data.fieldManagerStatus === "pending" ||
          data.branchManagerStatus === "pending" ||
          data.creditOfficerStatus === "pending" ||
          data.misStatus === "pending" ||
          data.creditManagerStatus === "pending" ||
          data.sanctionCommitteeStatus === "pending" ||
          data.accountManagerStatus === "pending"
        )
          return "pending";
        if (data.sanctionCommitteeStatus === "approved") return "approved";
        if (
          data.branchManagerStatus === "disbursed" ||
          data.misStatus === "disbursed"
        )
          return "disbursed";

        return "Unknown Status";
      },
    },
    {
      label: "Aadhar Number",
      render: (data) => data.aadharNo,
    },
    {
      label: "Pan/Other Identity",
      render: (data) =>
        data.isPanNo === 1 ? data.panNo : data.anotherIdentityId,
    },
    {
      label: "OnBoard Date",
      render: (data) =>
        new Date(data.createdAt).toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
    },
    {
      label: "Opened Time",
      render: (data) =>
        new Date(data.createdAt).toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
    },
    { label: "Employee Name", render: (data) => data.employeeName },
    { label: "Prospect ID", render: (data) => data.ApplicationId },

    // Add more columns as needed with custom render logic
  ];

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <h1>Client Prospect Report</h1>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>From Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={3}>
              <Form.Label>Branch</Form.Label>
              <Select
                value={selectedBranchFilter}
                onChange={setSelectedBranchFilter}
                options={branchOptions}
                isClearable
                placeholder="Filter by Branch Name"
                className="filter-dropdown"
              />
            </Col>
            <Col xs={12} lg={3}>
              <Form.Label>Employee</Form.Label>
              <Select
                value={selectedEmployeeFilter}
                onChange={setSelectedEmployeeFilter}
                options={employeeOptions}
                isClearable
                placeholder="Filter by Employee Name"
                className="filter-dropdown"
              />
            </Col>
          </Row>
          <DataTable
            columns={columns}
            data={paginationData}
            reportName="ClientProspectReport"
            excelData={filteredData}
          />
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            paginate={paginate}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientProspectReport;
