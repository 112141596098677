import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";

const DisburseMisModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  comment,
  setComment,
}) => {
  const [validationError, setValidationError] = useState("");

  const handleClick = () => {
    if (!comment) {
      setValidationError("Please fill all fields");
    } else {
      setValidationError("");
      handleConfirm();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <Form.Group>
          <Form.Label>Comment</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter Comment"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            required
          />
        </Form.Group>

        <p className="text-danger">{validationError}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick}>
          Disburse
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisburseMisModal;
