import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import axios from "axios";
import { BASE_URL } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoanDisbursedDetailModal = ({ show, handleClose, selectedMember }) => {
  const [loanDetails, setLoanDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [fundingAgencies, setFundingAgencies] = useState([]);
  const [loanFrequencies, setLoanFrequencies] = useState([]);
  const [loanTenures, setLoanTenures] = useState([]);

  useEffect(() => {
    const fetchLoanDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}/memberdetails/${selectedMember.memberDetails.memberId}/disbursed-loan-details`
        );
        if (response.status === 200) {
          const loanData = response.data;
          setLoanDetails(loanData);
          setEditedDetails({
            loanAccountNumber: loanData.loanAccountNumber,
            fundingAgency: { value: loanData.loanFundingAgencyId, label: loanData.fundingAgencyName },
            loanFrequency: { value: loanData.loanFrequencyId, label: loanData.loanFrequency },
            loanTenure: { value: loanData.loanTenureId, label: loanData.loanTenure }
          });
        }
      } catch (error) {
        console.error("Error fetching loan details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (show && selectedMember && selectedMember.memberDetails) {
      fetchLoanDetails();
    }
  }, [show, selectedMember]);

  useEffect(() => {
    const fetchFundingAgencies = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/get/fundingagency`);
        const options = response.data.fundingAgencies.map((agency) => ({
          value: agency.id,
          label: agency.name,
        }));
        setFundingAgencies(options);
      } catch (error) {
        console.error("Error fetching funding agencies:", error);
      }
    };

    const fetchLoanFrequencies = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/loan/frequencies`);
        const options = response.data.loanFrequencies.map((frequency) => ({
          value: frequency.id,
          label: frequency.loanfrequency,
        }));
        setLoanFrequencies(options);
      } catch (error) {
        console.error("Error fetching loan frequencies:", error);
      }
    };

    if (show) {
      fetchFundingAgencies();
      fetchLoanFrequencies();
    }
  }, [show]);

  useEffect(() => {
    const fetchLoanTenures = async (frequencyId) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/loan-tenures/${frequencyId}`
        );
        const options = response.data.loanTenures.map((tenure) => ({
          value: tenure.tenureId,
          label: tenure.loanTenure,
        }));
        setLoanTenures(options);
      } catch (error) {
        console.error("Error fetching loan tenures:", error);
      }
    };

    if (editedDetails.loanFrequency) {
      fetchLoanTenures(editedDetails.loanFrequency.value);
    }
  }, [editedDetails.loanFrequency]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        memberId: selectedMember.memberDetails.memberId,
        loanAccountNumber: editedDetails.loanAccountNumber,
        loanFundingAgencyId: editedDetails.fundingAgency.value,
        loanFrequencyId: editedDetails.loanFrequency.value,
        loanTenureId: editedDetails.loanTenure.value,
      };
      const response = await axios.put(`${BASE_URL}/memberdetails/detail/updateloandetails`, payload);
      if (response.status === 200) {
        

        setEditMode(false);
        handleClose();
        toast.success("Loan Details Updated successfully!");

      }
    } catch (error) {
      // console.error("Error saving loan details:", error);
      toast.error("Error in updating loan details:",);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditedDetails({
      loanAccountNumber: loanDetails.loanAccountNumber,
      fundingAgency: { value: loanDetails.loanFundingAgencyId, label: loanDetails.fundingAgencyName },
      loanFrequency: { value: loanDetails.loanFrequencyId, label: loanDetails.loanFrequency },
      loanTenure: { value: loanDetails.loanTenureId, label: loanDetails.loanTenure }
    });
    setEditMode(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: selectedOption,
    }));
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : null,
      "&:hover": {
        borderColor: state.isFocused ? "#80bdff" : "#adb5bd",
      },
    }),
  };

  return (
 <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Disbursed Loan Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p>Loading...</p>
        ) : loanDetails ? (
          <div>
            <div className="detail-row">
              <div className="label">Loan Account Number</div>
              <div className="colon">:</div>
              <div className="value">
                {editMode ? (
                  <Form.Control
                    type="text"
                    name="loanAccountNumber"
                    value={editedDetails.loanAccountNumber}
                    onChange={handleChange}
                  />
                ) : (
                  loanDetails.loanAccountNumber
                )}
              </div>
            </div>
            <div className="detail-row">
              <div className="label">Funding Agency</div>
              <div className="colon">:</div>
              <div className="value">
                {editMode ? (
                  <Select
                    name="fundingAgency"
                    options={fundingAgencies}
                    value={editedDetails.fundingAgency}
                    onChange={handleSelectChange}
                    styles={customStyles}
                  />
                ) : (
                  loanDetails.fundingAgencyName
                )}
              </div>
            </div>
            <div className="detail-row">
              <div className="label">Loan Frequency</div>
              <div className="colon">:</div>
              <div className="value">
                {editMode ? (
                  <Select
                    name="loanFrequency"
                    options={loanFrequencies}
                    value={editedDetails.loanFrequency}
                    onChange={handleSelectChange}
                    styles={customStyles}
                  />
                ) : (
                  loanDetails.loanFrequency
                )}
              </div>
            </div>
            <div className="detail-row">
              <div className="label">Loan Tenure</div>
              <div className="colon">:</div>
              <div className="value">
                {editMode ? (
                  <Select
                    name="loanTenure"
                    options={loanTenures}
                    value={editedDetails.loanTenure}
                    onChange={handleSelectChange}
                    styles={customStyles}
                    isDisabled={!editedDetails.loanFrequency}
                  />
                ) : (
                  loanDetails.loanTenure
                )}
              </div>
            </div>
          </div>
        ) : (
          <p>No disbursed loan details found.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {editMode ? (
          <>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={handleEdit}>
            Edit
          </Button>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    
    </Modal>
    <ToastContainer position="top-center" />
 </>
  );
};

export default LoanDisbursedDetailModal;
