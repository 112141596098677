// Utility function to calculate the first and last EMI dates based on disbursement date and tenure
export const getFirstAndLastEmiDates = (
  disbursementDate,
  tenureInMonths,
  emiDay
) => {
  const disbursement = new Date(disbursementDate);

  // First EMI Date: based on the EMI day in the disbursement month
  const firstEmiDate = new Date(
    disbursement.getFullYear(),
    disbursement.getMonth(),
    emiDay
  );
  // if (firstEmiDate < disbursement) {
  //   // If the EMI day has already passed in the disbursement month, move to the next month
  //   firstEmiDate.setMonth(firstEmiDate.getMonth() + 1);
  // }
  firstEmiDate.setMonth(firstEmiDate.getMonth() + 1);

  // Last EMI Date: based on the tenure and EMI day
  const lastEmiDate = new Date(
    disbursement.getFullYear(),
    disbursement.getMonth() + tenureInMonths,
    emiDay
  );

  return { firstEmiDate, lastEmiDate };
};

// Utility function to get EMI dates within the range of first EMI and last EMI
export const getEmiDatesInRange = (
  emiDay,
  fromDate,
  toDate,
  firstEmiDate,
  lastEmiDate
) => {
  const emiDates = [];
  const from = new Date(fromDate);
  const to = new Date(toDate);

  let currentDate = new Date(from);

  // console.log("fromDate: " + from);
  // console.log("toDate: " + to);
  // console.log("from as currentDate: " + currentDate);
  // console.log("emiDay: " + emiDay);

  while (currentDate <= to) {
    const emiDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      emiDay
    );
    if (emiDate >= firstEmiDate && emiDate <= lastEmiDate && emiDate <= to) {
      emiDates.push(emiDate); // Pushing the `emiDate` as it is
    }

    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  // console.log("emiDates: " + JSON.stringify(emiDates));
  return emiDates;
};

// Calculate EMI amount based on the provided formula
export const calculateEmiAmount = (
  sanctionedAmount,
  rateOfInterest,
  tenureInMonths
) => {
  const monthlyRate = rateOfInterest / 12 / 100;
  return (
    (sanctionedAmount *
      monthlyRate *
      Math.pow(1 + monthlyRate, tenureInMonths)) /
    (Math.pow(1 + monthlyRate, tenureInMonths) - 1)
  ).toFixed(2); // Fixed to 2 decimal points
};

// Helper function to calculate EMI breakdown into principal and interest components
const calculatePrincipalAndInterest = (
  remainingBalance,
  emiAmount,
  rateOfInterest
) => {
  const monthlyInterestRate = rateOfInterest / 12 / 100; // Monthly interest rate
  const interest = Math.round(remainingBalance * monthlyInterestRate);
  const principal = Math.round(emiAmount - interest); // EMI amount minus interest gives principal
  return { principal, interest };
};

// Function to calculate adjusted balance up to a specific date
const getAdjustedRemainingBalance = (
  data,
  emiAmount,
  rateOfInterest,
  firstEmiDate,
  fromDate
) => {
  let remainingBalance = data.sanctionedLoanAmountBySanctionCommittee;
  let currentEmiDate = new Date(firstEmiDate);

  while (currentEmiDate < new Date(fromDate)) {
    const { principal } = calculatePrincipalAndInterest(
      remainingBalance,
      emiAmount,
      rateOfInterest
    );
    remainingBalance -= principal; // Deduct principal paid for each EMI before fromDate
    currentEmiDate.setMonth(currentEmiDate.getMonth() + 1); // Move to the next EMI date
  }

  return remainingBalance;
};

// Generate rows with EMI dates within the range of first EMI date and last EMI date
export const generateRowsWithEmiDates = (data, fromDate, toDate) => {
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    const emiAmount = calculateEmiAmount(
      data.sanctionedLoanAmountBySanctionCommittee,
      data.proposedLoanDetails.rateOfInterest,
      data.proposedLoanDetails.tenureInMonths
    );

    const emiMonthsPaid = data.receiptsDetails
      ? data.receiptsDetails.filter(
          (receipt) => receipt.emiDate <= toDate && receipt.status === "paid"
        ).length
      : 0;

    // Adjust remaining balance based on EMIs before fromDate
    let remainingBalance = getAdjustedRemainingBalance(
      data,
      emiAmount,
      data.proposedLoanDetails.rateOfInterest,
      firstEmiDate,
      fromDate
    );

    const result = emiDates.map((emiDate) => {
      const { principal, interest } = calculatePrincipalAndInterest(
        remainingBalance,
        emiAmount,
        data.proposedLoanDetails.rateOfInterest
      );

      remainingBalance -= principal;

      return {
        ...data,
        formattedEmiDateByBranchManager: emiDate,
        emiAmount, // EMI amount for each row
        emiMonthsPaid, // Total EMI months paid up to the toDate
        principal, // Principal portion of the current EMI
        interest, // Interest portion of the current EMI
      };
    });

    // Log the result before returning
    // console.log("EMI Data:", JSON.stringify(result));

    return result;
  }
  return []; // No EMI dates to display if not disbursed
};

export const generateTotalPendingAmountForMember = (data, fromDate, toDate) => {
  // Check if disbursement date exists and calculate first/last EMI date
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    // Get EMI dates within the range
    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    // Calculate EMI amount
    const emiAmount = calculateEmiAmount(
      data.sanctionedLoanAmountBySanctionCommittee,
      data.proposedLoanDetails.rateOfInterest,
      data.proposedLoanDetails.tenureInMonths
    );

    // Initialize total pending amount and track the first fully pending EMI date
    let totalPendingAmount = 0;
    let firstPendingEmiDate = null;

    // Loop through all EMI dates and calculate total pending amount
    emiDates.forEach((emiDate) => {
      const formattedEmiDate = `${emiDate.getFullYear()}-${String(
        emiDate.getMonth() + 1
      ).padStart(2, "0")}-${String(emiDate.getDate()).padStart(2, "0")}`;

      // Get receipts for this emiDate
      const receiptsForEmiDate = data.receiptsDetails.filter(
        (receipt) => receipt.emiDate === formattedEmiDate
      );

      // Check if any receipt for this emiDate is marked as "paid"
      const isFullyPending = receiptsForEmiDate.every(
        (receipt) => receipt.status === "pending"
      );

      // Calculate the total received amount for this emiDate
      const totalReceivedAmount = receiptsForEmiDate.reduce(
        (sum, receipt) => sum + receipt.receivedAmount,
        0
      );

      // Calculate pending amount for this EMI date
      let pendingAmount = 0;
      if (receiptsForEmiDate.length === 0) {
        // Full EMI is pending if no receipts are found
        pendingAmount = emiAmount;
      } else if (totalReceivedAmount < emiAmount) {
        // Partial payment, remaining amount is pending
        pendingAmount = emiAmount - totalReceivedAmount;
      }

      // If this EMI date is fully pending and no previous pending EMI was found, set this as the first pending EMI date
      if (pendingAmount > 0 && isFullyPending && !firstPendingEmiDate) {
        firstPendingEmiDate = emiDate;
      }

      // Accumulate pending amount for this date to totalPendingAmount
      totalPendingAmount += Math.round(pendingAmount); // Use rounding to avoid floating-point issues
    });

    // Calculate overdue days if there is a first pending EMI date
    let overdueDays = 0;
    if (firstPendingEmiDate) {
      const currentDate = new Date();
      const timeDiff = currentDate - firstPendingEmiDate;
      overdueDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    }

    // If totalPendingAmount is greater than 0, return a single row with overdueDays
    if (totalPendingAmount > 0) {
      return {
        ...data,
        totalPendingAmount, // The total pending amount across all EMI dates
        status: "Pending",
        overdueDays, // Add overdue days from the first fully pending EMI date
      };
    }
  }

  return null; // No pending amount or not disbursed
};

export const generateOutstandingAmountsForParMember = (
  data,
  fromDate,
  toDate
) => {
  // Check if the loan has been disbursed and has an updated status
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    // Get EMI dates within the range
    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    // Calculate EMI amount
    const emiAmount = Math.round(
      calculateEmiAmount(
        data.sanctionedLoanAmountBySanctionCommittee,
        data.proposedLoanDetails.rateOfInterest,
        data.proposedLoanDetails.tenureInMonths
      )
    );

    // Initialize total outstanding amounts
    let totalOutstandingPrincipal = Math.round(
      data.sanctionedLoanAmountBySanctionCommittee
    );
    let totalOutstandingInterest = 0;
    let firstMissedEmiDate = null; // Track the first missed EMI date
    let hasMissedEmi = false; // Flag to track if any EMI has been missed

    // Loop through all EMI dates and calculate outstanding principal and interest
    emiDates.forEach((emiDate) => {
      const formattedEmiDate = `${emiDate.getFullYear()}-${String(
        emiDate.getMonth() + 1
      ).padStart(2, "0")}-${String(emiDate.getDate()).padStart(2, "0")}`;

      // Get receipts for this emiDate
      const receiptsForEmiDate = data.receiptsDetails.filter(
        (receipt) => receipt.emiDate === formattedEmiDate
      );

      // Declare totalReceivedAmount as let to allow modification
      let totalReceivedAmount = Math.round(
        receiptsForEmiDate.reduce(
          (sum, receipt) => sum + receipt.receivedAmount,
          0
        )
      );

      // Calculate interest component for this EMI date
      const interestComponent = Math.round(
        (totalOutstandingPrincipal * data.proposedLoanDetails.rateOfInterest) /
          12 /
          100
      ); // Monthly interest on the outstanding principal

      // Check if the EMI payment is skipped
      if (totalReceivedAmount < emiAmount) {
        hasMissedEmi = true; // Set the flag to true if any EMI is missed

        // Set the first missed EMI date if it's not already set
        if (!firstMissedEmiDate) {
          firstMissedEmiDate = emiDate;
        }
      }

      // Calculate outstanding amounts
      if (totalReceivedAmount >= interestComponent) {
        // Deduct interest first
        totalOutstandingInterest += interestComponent; // Add this month's interest to total outstanding interest
        totalReceivedAmount -= interestComponent; // Remaining amount after paying interest

        // Deduct any remaining amount from the principal
        totalOutstandingPrincipal -= Math.round(totalReceivedAmount); // Round remaining principal
      } else {
        // If the received amount doesn't cover the interest, just add to the outstanding interest
        totalOutstandingInterest += Math.round(
          interestComponent - totalReceivedAmount
        ); // Remaining interest due
      }
    });

    // Calculate overdue days if there's a missed EMI date
    let overdueDays = 0;
    if (firstMissedEmiDate) {
      const currentDate = new Date();
      const timeDiff = currentDate - firstMissedEmiDate;
      overdueDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    }

    // Return only if any EMI has been missed
    if (hasMissedEmi) {
      return {
        ...data,
        totalOutstandingPrincipal: Math.round(totalOutstandingPrincipal),
        totalOutstandingInterest: Math.round(totalOutstandingInterest),
        overdueDays, // Include overdue days
      };
    }
  }

  return null; // No outstanding amount or not disbursed
};

export const generateOutstandingAmountsForAllMember = (
  data,
  fromDate,
  toDate
) => {
  // Check if the loan has been disbursed and has an updated status
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    // Get EMI dates within the range
    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    // Initialize total outstanding amounts
    let totalOutstandingPrincipal = Math.round(
      data.sanctionedLoanAmountBySanctionCommittee
    );
    let totalOutstandingInterest = 0;
    let principalPaid = 0;
    let interestPaid = 0;
    let totalEmiPaid = 0;

    // Loop through all EMI dates and calculate outstanding principal and interest
    emiDates.forEach((emiDate) => {
      const formattedEmiDate = `${emiDate.getFullYear()}-${String(
        emiDate.getMonth() + 1
      ).padStart(2, "0")}-${String(emiDate.getDate()).padStart(2, "0")}`;

      // Get receipts for this emiDate
      const receiptsForEmiDate = data.receiptsDetails.filter(
        (receipt) => receipt.emiDate === formattedEmiDate
      );

      // Calculate total received amount for this EMI date
      let totalReceivedAmount = Math.round(
        receiptsForEmiDate.reduce(
          (sum, receipt) => sum + receipt.receivedAmount,
          0
        )
      );

      totalEmiPaid += totalReceivedAmount;

      // Calculate interest component for this EMI date
      const interestComponent = Math.round(
        (totalOutstandingPrincipal * data.proposedLoanDetails.rateOfInterest) /
          12 /
          100
      ); // Monthly interest on the outstanding principal

      // Calculate outstanding amounts
      if (totalReceivedAmount >= interestComponent) {
        // Deduct interest first
        interestPaid += interestComponent;
        totalOutstandingInterest += interestComponent; // Add this month's interest to total outstanding interest
        totalReceivedAmount -= interestComponent; // Remaining amount after paying interest

        // Deduct remaining amount from the principal
        const principalReduction = Math.min(
          totalOutstandingPrincipal,
          totalReceivedAmount
        );
        principalPaid += principalReduction; // Add paid principal
        totalOutstandingPrincipal -= principalReduction;
      } else {
        // If the received amount doesn't cover the interest, just add to the outstanding interest
        interestPaid += totalReceivedAmount;
        totalOutstandingInterest += Math.round(
          interestComponent - totalReceivedAmount
        ); // Remaining interest due
      }
    });

    // Return outstanding amounts without checking for missed EMIs or overdue days
    return {
      ...data,
      totalOutstandingPrincipal: Math.round(totalOutstandingPrincipal),
      totalOutstandingInterest: Math.round(totalOutstandingInterest),
      principalPaid: Math.round(principalPaid),
      interestPaid: Math.round(interestPaid),
      totalEmiPaid: Math.round(totalEmiPaid),
    };
  }

  return null; // No outstanding amount or not disbursed
};

export const generateRowsWithEmiAndCollectedAmounts = (
  data,
  fromDate,
  toDate
) => {
  // Check if disbursement date exists and calculate first/last EMI date
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    // Get EMI dates within range
    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    // Calculate EMI amount
    const emiAmount = calculateEmiAmount(
      data.sanctionedLoanAmountBySanctionCommittee,
      data.proposedLoanDetails.rateOfInterest,
      data.proposedLoanDetails.tenureInMonths
    );

    // Return multiple rows for each EMI date within the range
    const result = emiDates.map((emiDate) => {
      const formattedEmiDate = emiDate.toLocaleDateString("en-CA");
      // console.log(
      //   "generateRowsWithEmiAndCollectedAmounts formattedEmiDate: " +
      //     formattedEmiDate
      // );
      // Find matching receipts for the memberId and emiDate
      const matchingReceipts = data.receiptsDetails?.filter(
        (receipt) =>
          receipt.memberId === data.id && receipt.emiDate === formattedEmiDate
      );

      // Sum up the collected amounts from the matching receipts
      const collectedEmiAmount = matchingReceipts?.reduce(
        (total, receipt) => total + (receipt.receivedAmount || 0),
        0
      );

      // Concatenate all the collected dates from matching receipts
      const collectedDates = matchingReceipts
        ?.map((receipt) => {
          const collectedDate = new Date(receipt.collectedDate).toLocaleString(
            "en-GB",
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            }
          );

          const collectedAmount = receipt.receivedAmount || 0; // Ensure there's a fallback if no amount is present

          // Format the output as "date: amount"
          return `${collectedDate}: Rs ${collectedAmount}`;
        })
        .filter(Boolean) // Filter out any null/undefined values
        .join(", "); // Join dates with a comma separator

      // Calculate emiMonthsPaid based on receiptsDetails array
      const emiMonthsPaid = data.receiptsDetails
        ? data.receiptsDetails.filter(
            (receipt) => receipt.emiDate <= toDate && receipt.status === "paid"
          ).length
        : 0;

      // Return the row with EMI and collected amounts
      return {
        ...data,
        formattedEmiDateByBranchManager: emiDate,
        emiAmount, // Add the calculated EMI amount
        collectedEmiAmount, // Add the total collected EMI amount for this EMI date
        collectedDates,
        emiMonthsPaid,
      };
    });

    return result;
  }

  return []; // No EMI dates to display if not disbursed
};

export const convertDateStringToDMY = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-");
  return `${day}-${month}-${year}`;
};

export const formatISODateToLocalDMY = (dateString) => {
  if (!dateString) return "";

  // Convert the dateString to a Date object
  const date = new Date(dateString);

  // Get the day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const getCurrentDateTimeWithOffset = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  // Get timezone offset in hours and minutes
  const offset = -now.getTimezoneOffset();
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(
    2,
    "0"
  );
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");
  const offsetSign = offset >= 0 ? "+" : "-";

  // Format date with time and timezone offset
  // return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${offsetSign}${offsetHours}:${offsetMinutes}`;
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export const maskAadharNumber = (aadharNo) => {
  if (!aadharNo) return ""; // Handle undefined or null cases
  const aadharStr = String(aadharNo); // Convert to string if it's a number
  return `${aadharStr.slice(0, -4).replace(/\d/g, "*")}${aadharStr.slice(-4)}`;
};

export const getPendingEmiAmountForMember = (data) => {
  const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
    data.branchManagerStatusUpdatedAt,
    data.proposedLoanDetails.tenureInMonths,
    data.emiDateByBranchManager
  );

  // Get EMI dates within the range
  const emiDates = getEmiDatesInRange(
    data.emiDateByBranchManager,
    firstEmiDate,
    new Date(),
    firstEmiDate,
    lastEmiDate
  );

  const emiAmount = calculateEmiAmount(
    data.sanctionedLoanAmountBySanctionCommittee,
    data.proposedLoanDetails.rateOfInterest,
    data.proposedLoanDetails.tenureInMonths
  );

  const amountToReceive = emiDates.length * Math.round(emiAmount);
  const receiptsForMemberId = data.receiptsDetails.filter(
    (receipt) => receipt.memberId === data.id
  );
  const totalReceivedAmount = receiptsForMemberId.reduce(
    (sum, receipt) => sum + receipt.receivedAmount,
    0
  );
  const pendingEmiAmount = amountToReceive - totalReceivedAmount;
  return Math.round(pendingEmiAmount);
};

export const getFutureEmiAmountWithMonths = (data) => {
  const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
    data.branchManagerStatusUpdatedAt,
    data.proposedLoanDetails.tenureInMonths,
    data.emiDateByBranchManager
  );

  // Get EMI dates within the range
  const emiDates = getEmiDatesInRange(
    data.emiDateByBranchManager,
    new Date(),
    lastEmiDate,
    firstEmiDate,
    lastEmiDate
  );

  const emiAmount = calculateEmiAmount(
    data.sanctionedLoanAmountBySanctionCommittee,
    data.proposedLoanDetails.rateOfInterest,
    data.proposedLoanDetails.tenureInMonths
  );

  const amountToReceive = emiDates.length * Math.round(emiAmount);
  return `${Math.round(amountToReceive)}/${emiDates.length}`;
};

export const getTotalEmiPaid = (data) => {
  const receiptsForMemberId = data.receiptsDetails.filter(
    (receipt) => receipt.memberId === data.id
  );
  const totalReceivedAmount = receiptsForMemberId.reduce(
    (sum, receipt) => sum + receipt.receivedAmount,
    0
  );
  return Math.round(totalReceivedAmount);
};

export const getStatusOfMember = (data) => {
  const tenureInMonths = data.proposedLoanDetails.tenureInMonths;
  const emiMonthsPaid = data.receiptsDetails
    ? data.receiptsDetails.filter((receipt) => receipt.status === "paid").length
    : 0;
  return tenureInMonths === emiMonthsPaid ? "Closed" : "Active";
};
