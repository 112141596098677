import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../env";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const  AddBusinessCategory = ({ show, handleClose }) => {
  
  const [businessCategory, setBusinessCategory] = useState("");

  const handleAddCategory = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/business-categories`, 
        { businessCategory :businessCategory});
        console.log(response)
      setBusinessCategory();
      handleClose()
    } catch (error) {
  
       handleClose();
       setBusinessCategory();
      alert("Error in adding new branch")
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Business Category</p>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
          
            <p className="mt-2">Business Category Name</p>
            <Form.Control
              type="text"
              placeholder="Enter Category Name"
              value={businessCategory}
              onChange={(e) => setBusinessCategory(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="bg-danger btn" style={{ color: 'white' }} onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-addCredential" onClick={handleAddCategory}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* Render ToastContainer */}
      <ToastContainer position="top-center" />
    </>
  );
};

export default  AddBusinessCategory;