import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { BASE_URL } from "../env"; 

const DisbursedExcelUploadModal = ({ show, handleClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(`${BASE_URL}/disbursed/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.existingLoanAccounts && responseData.existingLoanAccounts.length > 0) {
          setUploadMessage(`These loan account numbers already exist: ${responseData.existingLoanAccounts.join(', ')}`);
        } else {
          setUploadMessage("Upload successful!");
        }
        setShowMessageModal(true); 
        handleClose();
      } else {
        setUploadMessage("Upload failed!");
        setShowMessageModal(true); 
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadMessage("Error uploading file!");
      setShowMessageModal(true); 
    }
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    setUploadMessage(""); 
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <h6>Disbursement Excel Upload</h6>
        </Modal.Header>
        <Modal.Body>
          <input type="file" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMessageModal} onHide={handleCloseMessageModal}>
        <Modal.Header closeButton>
          <h6> Upload Status</h6>
        </Modal.Header>
        <Modal.Body>
          <p>{uploadMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseMessageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DisbursedExcelUploadModal;
