import React, { useState, useEffect } from "react";
import AddNatureOfBusiness from "./AddNatureOfBusiness";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import Select from "react-select";
import axios from "axios";
import { BASE_URL } from "../env";

const BusinessList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [natureOfBusinessList, setNatureOfBusinessList] = useState([]);

  useEffect(() => {
    // Fetch business categories on component mount
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/business-categories`);
        setCategory(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategory();
  }, [category]);

  const handleCategoryChange = async (selectedOption) => {
    setSelectedCategory(selectedOption);

    // Fetch nature of business based on selected category
    try {
      const response = await axios.get(
        `${BASE_URL}/api/nature-of-business/${selectedOption.value}`
      );
      setNatureOfBusinessList(response.data);
      
    } catch (error) {
      console.error("Error fetching nature of business:", error);
    }
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <AddNatureOfBusiness />
          <div className="search-container mt-2">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search by any field"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <span className="search-icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              options={category.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              isClearable
              placeholder="Select Business Category"
              className="w-75 mt-5"
            />
          </div>

          <div className="d-flex justify-content-center">
            <div className="table-responsive mt-4 w-75">
              <table className="table table-striped mx-auto table-list">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nature of Business Name</th>
                  </tr>
                </thead>
                <tbody>
                  {natureOfBusinessList.length > 0 ? (
                    natureOfBusinessList.map((business, index) => (
                      <tr key={business.businessCategoryId}>
                        <td>{index + 1}</td>
                        <td>{business.name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">
                        No nature of business found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessList;
