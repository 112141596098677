import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { BASE_URL } from "../env";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { Table, InputGroup, FormControl } from "react-bootstrap";
import MemberDetailsModal from "../components/MemberDetailsModal";
import { IoSearch } from "react-icons/io5";

const ApprovedMemberDetails = () => {
  const { adminUser } = useAdminAuth();
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [memberData, setMemberData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        let response;
        if (adminUser.userType === "superadmin") {
          response = await axios.get(
            `${BASE_URL}/api/getmemberdetails/ByAccountManagerId/?accountManagerId=${adminUser.user.id}&accountManagerStatus=accepted`
          );
        } else if (adminUser.userType === "Credit Manager") {
          response = await axios.get(
            `${BASE_URL}/api/getmemberdetails/ByCreditManagerId?creditManagerId=${adminUser.user.id}&creditManagerStatus=accepted`
          );
        } else if (adminUser.userType === "Branch Manager") {
          response = await axios.get(`${BASE_URL}/branch-manager/members`);
        } else if (adminUser.userType === "Accounts Manager") {
          response = await axios.get(
            `${BASE_URL}/api/getmemberdetails/ByAccountManagerId/?accountManagerId=${adminUser.user.id}&accountManagerStatus=accepted`
          );
        }
        setMemberData(response.data.list);
      } catch (error) {
        console.error("Error fetching member details:", error);
      }
    };

    fetchMemberDetails();
  }, [adminUser.user.id, adminUser.userType]);

  // Filter the entire dataset
  const filteredMembers = memberData.filter(
    (member) =>
      member.memberName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.branchName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMembers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMembers.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleShowModal = (memberId) => {
    setSelectedMemberId(memberId);
    setShowModal(true);
  };

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <div className="member-details mt-4">
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <IoSearch />
              </InputGroup.Text>
              <FormControl
                placeholder="Search by Member Name or Branch Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>

            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Member Photo</th>
                  <th>Member Name</th>
                  <th>Phone Number</th>
                  <th>Applied Date</th>
                  <th>Branch Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((member, index) => {
                    const combinedStatus = `
        Credit Officer: ${member.creditOfficerStatus} |
        ${
          adminUser.userType !== "Credit Manager"
            ? `Credit Manager: ${member.creditManagerStatus} |`
            : ""
        }
        Branch Manager: ${member.branchManagerStatus} |
        Account Manager: ${member.accountManagerStatus}
      `;

                    return (
                      <tr key={member.member_id}>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>
                          <img
                            src={`${BASE_URL}/images/${member.memberPhoto}`}
                            width={25}
                            alt="Member"
                          />
                        </td>
                        <td>{member.memberName}</td>
                        <td>{member.memberPhoneNumber}</td>
                        <td>{member.appliedDate}</td>
                        <td>{member.branchName}</td>
                        <td>{combinedStatus}</td>
                        <td>
                          <button
                            onClick={() => handleShowModal(member.member_id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No member data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            {/* Custom Pagination */}
            <nav className="custom-pagination">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link mx-2"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                <li key={currentPage} className="page-item active">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage)}
                  >
                    Page {currentPage} of {totalPages}, Total Data:{" "}
                    {filteredMembers.length}
                  </button>
                </li>
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link mx-2"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {showModal && (
        <MemberDetailsModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          memberId={selectedMemberId}
        />
      )}
    </div>
  );
};

export default ApprovedMemberDetails;
