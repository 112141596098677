import React, { useState } from "react";
import AddLoginCredential from "../components/AddLoginCredential";
// import DivisionalManagerList from "./DivisionalManagerList";
import AccountsManagerList from "./AccountsManagerList";
import FieldManagerList from "./FieldManagerList";
import BranchManagerList from "./BranchManagerList";
import Select from "react-select";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import CreditManagerList from "./CreditManagerList";
import CreditOfficerList from "../components/CreditOfficerList";
import MISList from "./MISList";
import SanctionCommitteeList from "./SanctionCommitteeList";

const LoginCredentials = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [refreshList, setRefreshList] = useState(false);

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption.value);
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div className="dashboard-over">
      <div className="d-flex ">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid "
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />

          <div className="search-container mt-3">
            <AddLoginCredential
              onCredentialAdded={() => setRefreshList(!refreshList)}
            />
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search by any field"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <span className="search-icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="mt-4 mx-auto selectRole">
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              options={[
                { value: "Accounts Manager", label: "Accounts Manager" },
                { value: "Sanction Committee", label: "Sanction Committee" },
                { value: "Credit Manager", label: "Credit Manager" },
                { value: "MIS", label: "MIS" },
                { value: "Credit Officer", label: "Credit Officer" },
                { value: "Branch Manager", label: "Branch Manager" },
                {
                  value: "Customer Relationship Officer",
                  label: "Customer Relationship Officer",
                },
              ]}
              isClearable
              placeholder="---Select Role---"
              className="w-100"
            />
          </div>

          {selectedRole === "Accounts Manager" && (
            <AccountsManagerList
              searchQuery={searchQuery}
              refreshList={refreshList}
            />
          )}
          {selectedRole === "MIS" && (
            <MISList searchQuery={searchQuery} refreshList={refreshList} />
          )}
          {selectedRole === "Sanction Committee" && (
            <SanctionCommitteeList
              searchQuery={searchQuery}
              refreshList={refreshList}
            />
          )}
          {selectedRole === "Branch Manager" && (
            <BranchManagerList
              searchQuery={searchQuery}
              refreshList={refreshList}
            />
          )}
          {selectedRole === "Credit Manager" && (
            <CreditManagerList
              searchQuery={searchQuery}
              refreshList={refreshList}
            />
          )}
          {selectedRole === "Credit Officer" && (
            <CreditOfficerList
              searchQuery={searchQuery}
              refreshList={refreshList}
            />
          )}
          {selectedRole === "Customer Relationship Officer" && (
            <FieldManagerList
              searchQuery={searchQuery}
              refreshList={refreshList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginCredentials;
