import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../env";
import Sidebar from "../components/Sidebar";
import { Form, Row, Col, Button } from "react-bootstrap";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "./MemberPrint.css";
import {
  getCurrentDateTimeWithOffset,
  maskAadharNumber,
  getPendingEmiAmountForMember,
  getFutureEmiAmountWithMonths,
  getTotalEmiPaid,
  generateOutstandingAmountsForAllMember,
  getFirstAndLastEmiDates,
  formatISODateToLocalDMY,
  getStatusOfMember,
} from "../components/utils";

const AccountStatementReport = () => {
  const ref = useRef();
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [cusApplicationId, setCusApplicationId] = useState("");
  const [memberDetails, setMemberDetails] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [showStatement, setShowStatement] = useState(false);
  const [loading, setLoading] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleClickViewStatement = async () => {
    setValidationError("");
    setShowStatement(false);
    if (!cusApplicationId) {
      setValidationError("Please enter Prospect ID");
    }
    try {
      const response = await axios.get(
        `${BASE_URL}/api/getAccountStatmentReport/${cusApplicationId}`
      );
      if (response.data.message) {
        setMemberDetails(response.data.message);
        setShowStatement(true);
        // console.log("memberDetails: " + JSON.stringify(response.data.message));
      } else if (response.data.error) {
        setValidationError(response.data.error);
      }
    } catch (error) {
      console.log("Error fetching member details:", error);
      setValidationError(error);
    }
  };

  const handleDownloadStatement = async () => {
    setLoading(true);

    const input = ref.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const originalPdfWidth = pdf.internal.pageSize.getWidth();
    const originalPdfHeight = pdf.internal.pageSize.getHeight();
    const footerHeight = 20; // Reserve space for the footer
    const pdfWidth = originalPdfWidth * 0.9;
    const pdfHeight = originalPdfHeight * 0.9 - footerHeight; // Adjusted height for content
    const xOffset = (originalPdfWidth - pdfWidth) / 2;
    const yOffset = (originalPdfHeight - pdfHeight - footerHeight) / 2; // Adjust for footer
    const sections = input.querySelectorAll(".section");

    let heightLeft = pdfHeight;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      const canvas = await html2canvas(section, { useCORS: true, scale: 2 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (
        heightLeft < imgHeight ||
        section.classList.contains("sanction-letter-section")
      ) {
        addFooter(pdf, originalPdfWidth, originalPdfHeight);
        pdf.addPage();
        heightLeft = pdfHeight;
      }

      pdf.addImage(
        imgData,
        "JPEG",
        xOffset,
        yOffset + (pdfHeight - heightLeft),
        pdfWidth,
        imgHeight
      );
      heightLeft -= imgHeight;

      // Add border to the current page after content is added
      pdf.rect(5, 5, originalPdfWidth - 10, originalPdfHeight - 10);
    }

    // Add footer to the last page
    addFooter(pdf, originalPdfWidth, originalPdfHeight);

    pdf.setProperties({
      title: "Document",
      subject: "Generated Document",
      author: "Your Name",
      keywords: "generated, javascript, web 2.0, ajax",
      creator: "Your App",
    });

    pdf.save(`${memberDetails.memberDetails.memberName}-Account Statement`);
    setLoading(false);
  };

  // Function to add footer with horizontal line and company details
  const addFooter = (pdf, width, height) => {
    const footerY = height - 15; // Set footer position above bottom edge

    // Draw horizontal line
    pdf.setLineWidth(0.3);
    pdf.line(10, footerY, width - 10, footerY);

    // Add company name and address
    pdf.setFontSize(6);
    pdf.setFont("helvetica", "bold");
    pdf.text("VETRIVIKAS CAPITAL TRUST", 10, footerY + 5);
    pdf.text("Registered Office:", 10, footerY + 8);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      "Flat No.25,ARUNACHALESHWARAR 3RD CROSS STREET,HMS COLONY 1ST MAIN ROAD, KOKKALAPPI, MADURAI, TAMILNADU-625016. Office No: 0452-490 9438",
      10 + pdf.getTextWidth("Registered Office: "),
      footerY + 8
    );
  };

  const memberInfo = memberDetails?.memberDetails
    ? [
        {
          label: "Customer Name",
          value: memberDetails.memberDetails.memberName,
        },
        {
          label: "Address",
          value: `${memberDetails.memberDetails.permanentDoorNo}, ${memberDetails.memberDetails.permanentStreetNo}, ${memberDetails.memberDetails.permanentPanchayat}, ${memberDetails.memberDetails.permanentTaluk}, ${memberDetails.memberDetails.permanentDistrict}-${memberDetails.memberDetails.permanentPincode}`,
        },
        {
          label: "Agreement No.",
          value: memberDetails.memberDetails.ApplicationId,
        },
        {
          label: "Branch",
          value: memberDetails.branchName,
        },
        {
          label: "Loan Amount",
          value:
            memberDetails.memberDetails.sanctionedLoanAmountBySanctionCommittee,
        },
        {
          label: "CKYC ID",
          value: maskAadharNumber(memberDetails.memberDetails.aadharNo),
        },
        {
          label: "Installment Overdue",
          value: getPendingEmiAmountForMember(memberDetails.memberDetails),
        },
        {
          label: "Currency",
          value: "INR",
        },
        {
          label: "Future Installments",
          value: getFutureEmiAmountWithMonths(memberDetails.memberDetails),
        },
        {
          label: "Installment Paid",
          value: getTotalEmiPaid(memberDetails.memberDetails),
        },
        {
          label: "Principal Paid",
          value: (() => {
            const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
              memberDetails.memberDetails.branchManagerStatusUpdatedAt,
              memberDetails.memberDetails.proposedLoanDetails.tenureInMonths,
              memberDetails.memberDetails.emiDateByBranchManager
            );
            const { principalPaid } = generateOutstandingAmountsForAllMember(
              memberDetails.memberDetails,
              firstEmiDate,
              lastEmiDate
            );
            return principalPaid ? principalPaid : 0;
          })(),
        },
        {
          label: "Interest Paid",
          value: (() => {
            const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
              memberDetails.memberDetails.branchManagerStatusUpdatedAt,
              memberDetails.memberDetails.proposedLoanDetails.tenureInMonths,
              memberDetails.memberDetails.emiDateByBranchManager
            );
            const { interestPaid } = generateOutstandingAmountsForAllMember(
              memberDetails.memberDetails,
              firstEmiDate,
              lastEmiDate
            );
            return interestPaid ? interestPaid : 0;
          })(),
        },
        {
          label: "Monthly Reducing Date",
          value: "26%",
        },
        {
          label: "Sanction Date",
          value: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
        },
        {
          label: "Status",
          value: getStatusOfMember(memberDetails.memberDetails),
        },
        {
          label: "Interest Rate Type",
          value: "Fixed",
        },
        {
          label: "Tenure (months)",
          value: memberDetails.memberDetails.proposedLoanDetails.tenureInMonths,
        },
        {
          label: "Frequency",
          value: "Monthly EMIs",
        },
        {
          label: "Installment Period",
          value: (() => {
            const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
              memberDetails.memberDetails.branchManagerStatusUpdatedAt,
              memberDetails.memberDetails.proposedLoanDetails.tenureInMonths,
              memberDetails.memberDetails.emiDateByBranchManager
            );
            return firstEmiDate && lastEmiDate
              ? firstEmiDate.toLocaleDateString() +
                  " to " +
                  lastEmiDate.toLocaleDateString()
              : "";
          })(),
        },
      ]
    : [];

  const accountStatementReport = memberDetails?.memberDetails
    ? [
        {
          receivedDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          emiDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          particulars: "Loan Amount",
          increasedBy: "",
          decreasedBy:
            memberDetails.memberDetails.sanctionedLoanAmountBySanctionCommittee,
          otherDues: "",
        },
        {
          receivedDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          emiDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          particulars: "Life insurance premium Due",
          increasedBy: memberDetails.memberDetails.insuranceAmount
            ? memberDetails.memberDetails.insuranceAmount
            : 0,
          decreasedBy: "",
          otherDues: "",
        },
        {
          receivedDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          emiDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          particulars: "Processing Fee",
          increasedBy: memberDetails.memberDetails.processingCharge
            ? memberDetails.memberDetails.processingCharge
            : 0,
          decreasedBy: "",
          otherDues: "",
        },
        {
          receivedDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          emiDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          particulars: "GST",
          increasedBy: memberDetails.memberDetails.gst
            ? memberDetails.memberDetails.gst
            : 0,
          decreasedBy: "",
          otherDues: "",
        },
        {
          receivedDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          emiDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.branchManagerStatusUpdatedAt
          ),
          particulars: "Security Deposit",
          increasedBy: memberDetails.memberDetails.securityDeposit
            ? memberDetails.memberDetails.securityDeposit
            : 0,
          decreasedBy: "",
          otherDues: "",
        },
        {
          receivedDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.transactionDate
          ),
          emiDate: formatISODateToLocalDMY(
            memberDetails.memberDetails.transactionDate
          ),
          particulars: "Credit Loan Amount",
          increasedBy: memberDetails.memberDetails.transactionAmount
            ? memberDetails.memberDetails.transactionAmount
            : 0,
          decreasedBy: "",
          otherDues: "",
        },
      ]
    : [];

  const totalSumIncreasedBy = accountStatementReport.reduce(
    (sum, item) => sum + Number(item.increasedBy || 0),
    0
  );

  const totalSumDecreasedBy = accountStatementReport.reduce(
    (sum, item) => sum + Number(item.decreasedBy || 0),
    0
  );

  const totalSumReceivedAmount =
    memberDetails?.memberDetails?.receiptsDetails.reduce(
      (sum, item) => sum + Number(item.receivedAmount || 0),
      0
    );

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <h1>Account Statement Report</h1>
          <Row>
            <Col xs={12} lg={3}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter Prospect ID"
                  value={cusApplicationId}
                  onChange={(event) => setCusApplicationId(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={3}>
              <Form.Group>
                <Button onClick={handleClickViewStatement}>
                  View Statement
                </Button>
              </Form.Group>
            </Col>
            {showStatement && (
              <Col xs={12} lg={3}>
                <Form.Group>
                  <Button onClick={handleDownloadStatement} disabled={loading}>
                    {loading ? "Downloading..." : "Download PDF"}
                  </Button>
                </Form.Group>
              </Col>
            )}
            <h3 className="text-danger mt-2">{validationError}</h3>
          </Row>
          {showStatement && (
            <div ref={ref} className="print-container">
              <div className="section d-flex justify-content-end ">
                <img
                  src={require("../assets/vetrivikaslogo.jpeg")}
                  alt="logo"
                  className="printlogo"
                />
              </div>
              <div className="header section">
                <b>Account Statement</b>
              </div>
              <p className="section">
                <i>Generated On: {getCurrentDateTimeWithOffset()}</i>
              </p>
              <div>
                <table
                  className="table table-striped table-bordered"
                  style={{ border: "1px solid black" }}
                >
                  <tbody>
                    {memberInfo.map((item, index) => (
                      <tr key={index} className="section">
                        <th>{item.label}</th>
                        <td>{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-5">
                <table
                  className="table table-striped table-bordered"
                  style={{ border: "1px solid black" }}
                >
                  <thead>
                    <tr className="section">
                      <th>Received Date</th>
                      <th>EMI Date</th>
                      <th>Particular</th>
                      <th>Increased By</th>
                      <th>Decreased By</th>
                      <th>Other Dues</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountStatementReport.map((item, index) => (
                      <tr key={index} className="section">
                        <td>{item.receivedDate}</td>
                        <td>{item.emiDate}</td>
                        <td>{item.particulars}</td>
                        <td>{item.increasedBy}</td>
                        <td>{item.decreasedBy}</td>
                        <td>{item.otherDues}</td>
                      </tr>
                    ))}
                    {memberDetails.memberDetails.receiptsDetails.map(
                      (item, index) => (
                        <>
                          <tr key={index} className="section">
                            <td></td>
                            <td>{item.emiDate}</td>
                            <td>Due for Installment {index + 1}</td>
                            <td>{item.receivedAmount}</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr key={index} className="section">
                            <td>{item.emiDate}</td>
                            <td></td>
                            <td>Payment Received From Cash</td>
                            <td></td>
                            <td>{item.receivedAmount}</td>
                            <td></td>
                          </tr>
                        </>
                      )
                    )}
                    <tr className="section">
                      <th></th>
                      <th></th>
                      <th>Total: </th>
                      <th>{totalSumIncreasedBy + totalSumReceivedAmount}</th>
                      <th>{totalSumDecreasedBy + totalSumReceivedAmount}</th>
                      <th></th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="section">
                <p>
                  This is a computer generated statement and does not require
                  any signature.
                </p>
                <h3 className="text-center">*** END OF REPORT ***</h3>
                <p>
                  DISCLAIMER: This is a system generated Account Statement and
                  does not require signature. In case of any discrepancy in this
                  Account Statement, please call 0452-490 9438 or visit nearest
                  Vetrivikas Capital Trust branch within 10 (TEN) days from the
                  date of last entry made in this statement, failing which, this
                  Account Statement will be deemed to be accepted by you (the
                  borrower).
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountStatementReport;
