import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../env";
import Sidebar from "../components/Sidebar";
import { Form, Row, Col } from "react-bootstrap";
import {
  getFirstAndLastEmiDates,
  calculateEmiAmount,
} from "../components/utils";
import CustomPagination from "../components/CustomPagination";
import DataTable from "../components/DataTable";

const MasterDataReport = () => {
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("2024-08-17");
  const [toDate, setToDate] = useState("");

  const itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginationData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const getReportData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/getMasterReportData`);
        // console.log(
        //   "MasterData response.data: " + JSON.stringify(response.data)
        // );
        if (response.data) {
          setReportData(response.data);
        } else {
          console.log("Error in fetching Data");
        }
      } catch (error) {
        console.log("Error fetching Data:", error);
      }
    };

    getReportData();
  }, []);

  // Automatically filter data when fromDate or toDate changes
  useEffect(() => {
    if (fromDate && toDate) {
      const filtered = reportData
        .map((item) => {
          // Calculate emiMonthsPaid based on receiptsDetails array
          const emiMonthsPaid = item.receiptsDetails
            ? item.receiptsDetails.filter(
                (receipt) =>
                  new Date(receipt.emiDate) >= new Date(fromDate) &&
                  new Date(receipt.emiDate) <= new Date(toDate) &&
                  receipt.status === "paid"
              ).length
            : 0;

          return {
            ...item,
            emiMonthsPaid, // Add emiMonthsPaid to each item
          };
        })
        .filter((item) => {
          // Further filter data based on branchManagerStatusUpdatedAt within the date range
          const updatedAt = new Date(item.branchManagerStatusUpdatedAt);
          return (
            updatedAt >= new Date(fromDate) && updatedAt <= new Date(toDate)
          );
        });

      setFilteredData(filtered);
    } else {
      setFilteredData([]); // Show no data if dates are not selected
    }
  }, [fromDate, toDate, reportData]);

  // Define columns with label and render logic
  const columns = [
    { label: "S No", render: (data, index) => index + 1 },
    { label: "Region", render: (data) => data.regionName },
    { label: "Division", render: (data) => data.divisionName },
    { label: "Branch Name", render: (data) => data.branchName },
    { label: "Branch Code", render: (data) => data.branchCode },
    { label: "Customer Name", render: (data) => data.memberName },
    { label: "Customer ID", render: (data) => data.customerId },
    {
      label: "Address",
      render: (data) =>
        `${data.permanentDoorNo},${data.permanentStreetNo},${data.permanentPanchayat},${data.permanentTaluk},${data.permanentDistrict}-${data.permanentPincode}`,
    },
    { label: "Contact No", render: (data) => data.phoneNumber },
    { label: "Loan Type", render: (data) => "Business Loan" },
    { label: "Loan Cycle", render: (data) => data.loanCycle },
    { label: "Loan Account No", render: (data) => data.loanId },
    {
      label: "Rate of Interest",
      render: (data) => data.proposedLoanDetails.rateOfInterest,
    },
    {
      label: "Disbursement Date",
      render: (data) =>
        data.branchManagerStatus === "disbursed" &&
        data.branchManagerStatusUpdatedAt
          ? new Date(data.branchManagerStatusUpdatedAt).toLocaleDateString(
              "en-GB",
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            )
          : "",
    },
    {
      label: "Maturity Date",
      render: (data) => {
        const { lastEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return lastEmiDate ? lastEmiDate.toLocaleDateString() : "";
      },
    },
    {
      label: "Disbursed Amount",
      render: (data) =>
        data.branchManagerStatus === "disbursed"
          ? data.sanctionedLoanAmountBySanctionCommittee
          : "",
    },
    {
      label: "Tenure In Months",
      render: (data) => data.proposedLoanDetails.tenureInMonths,
    },
    {
      label: "EMI months Paid",
      render: (data) => data.emiMonthsPaid,
    },
    { label: "Employee Name", render: (data) => data.employeeName },
    {
      label: "EMI Start Date",
      render: (data) => {
        const { firstEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return firstEmiDate ? firstEmiDate.toLocaleDateString() : "";
      },
    },
    { label: "Prospect ID", render: (data) => data.ApplicationId },
    {
      label: "Processing Fee",
      render: (data) => (data.processingCharge ? data.processingCharge : ""),
    },
    { label: "GST", render: (data) => (data.gst ? data.gst : "") },
    {
      label: "Insurance",
      render: (data) => (data.isLoanInsured ? data.insuranceAmount : 0),
    },
    {
      label: "Security Deposit",
      render: (data) => {
        const emiAmount = calculateEmiAmount(
          data.sanctionedLoanAmountBySanctionCommittee,
          data.proposedLoanDetails.rateOfInterest,
          data.proposedLoanDetails.tenureInMonths
        );
        return emiAmount ? Math.round(emiAmount) : "";
      },
    },
    {
      label: "EMI Amount",
      render: (data) => {
        const emiAmount = calculateEmiAmount(
          data.sanctionedLoanAmountBySanctionCommittee,
          data.proposedLoanDetails.rateOfInterest,
          data.proposedLoanDetails.tenureInMonths
        );
        return emiAmount ? Math.round(emiAmount) : "";
      },
    },
    {
      label: "Loan Purpose",
      render: (data) => data.businessDetails.businessType,
    },
    {
      label: "Aadhar Number",
      render: (data) => data.aadharNo,
    },
    {
      label: "Pan Number",
      render: (data) => (data.panNo ? data.panNo : ""),
    },
    {
      label: "Voter Id",
      render: (data) =>
        data.anotherIdentity === "Voter Id" ? data.anotherIdentityId : "",
    },
    {
      label: "Bank Account Number",
      render: (data) => data.bankDetails.accountNumber,
    },
    {
      label: "IFSC Code",
      render: (data) => data.bankDetails.ifscCode,
    },
    {
      label: "Bank Name",
      render: (data) => data.bankDetails.bankName,
    },
    {
      label: "Bank Branch",
      render: (data) => data.bankDetails.bankBranch,
    },
    // Add more columns as needed with custom render logic
  ];

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <h1>Master Data Report</h1>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>From Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <DataTable
            columns={columns}
            data={paginationData}
            reportName="MasterDataReport"
            excelData={filteredData}
          />
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            paginate={paginate}
          />
        </div>
      </div>
    </div>
  );
};

export default MasterDataReport;
