import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import AddLoanFrequencyModal from "../components/AddLoanFrequencyModal";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";

const LoanFrequencyList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loanFrequencies, setLoanFrequencies] = useState([]);
  const [filteredLoanFrequencies, setFilteredLoanFrequencies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loanFrequenciesPerPage] = useState(20);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedLoanFrequency, setSelectedLoanFrequency] = useState(null);

  useEffect(() => {
    fetchLoanFrequencies();
  }, []);

  const fetchLoanFrequencies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/loan/frequencies`);
      setLoanFrequencies(response.data.loanFrequencies);
      setFilteredLoanFrequencies(response.data.loanFrequencies);
    } catch (error) {
      console.error("Error fetching loan frequencies:", error);
    }
  };

  useEffect(() => {
    const filtered = loanFrequencies.filter((loanFrequency) =>
      loanFrequency.loanfrequency.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredLoanFrequencies(filtered);
    setCurrentPage(1);
  }, [searchQuery, loanFrequencies]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleAddLoanFrequencyClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchLoanFrequencies();
  };

  const handleEditLoanFrequency = (loanFrequency) => {
    setSelectedLoanFrequency(loanFrequency);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedLoanFrequency(null);
  };

  const handleSaveChanges = async () => {
    if (selectedLoanFrequency) {
      try {
        const response = await axios.put(`${BASE_URL}/admin/edit/loanfrequency`, {
          loanfrequencyId: selectedLoanFrequency.id,
          loanfrequency: selectedLoanFrequency.loanfrequency,
        });
        if (response.status === 200) {
          fetchLoanFrequencies();
          handleCloseEditModal();
        } else {
          console.error("Failed to update loan frequency");
        }
      } catch (error) {
        console.error("Error updating loan frequency:", error);
      }
    }
  };

  const indexOfLastLoanFrequency = currentPage * loanFrequenciesPerPage;
  const indexOfFirstLoanFrequency = indexOfLastLoanFrequency - loanFrequenciesPerPage;
  const currentLoanFrequencies = filteredLoanFrequencies.slice(indexOfFirstLoanFrequency, indexOfLastLoanFrequency);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredLoanFrequencies.length / loanFrequenciesPerPage);

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
        <div className="container-fluid" style={{ overflowY: "scroll", height: "100vh", scrollbarWidth: 'none' }}>
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <div>
            <button className="btn btn-addCredential mt-3" onClick={handleAddLoanFrequencyClick}>
              Add Loan Frequency
            </button>
          </div>
          <div className="search-container" style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginTop: "50px" }}>
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search by loan frequency"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <span className="search-icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="mt-4 text-center">
            <h5 className="pb-3">Loan Frequency List</h5>
            <div className="table-responsive">
              <table className="mx-auto table table-striped table-list">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Loan Frequency</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentLoanFrequencies.length > 0 ? (
                    currentLoanFrequencies.map((loanFrequency, index) => (
                      <tr key={loanFrequency.id}>
                        <td>{index + indexOfFirstLoanFrequency + 1}</td>
                        <td>{loanFrequency.loanfrequency}</td>
                        <td>
                          <button className="btn btn-edit" title="Edit" onClick={() => handleEditLoanFrequency(loanFrequency)}>
                            <MdModeEdit />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No loan frequencies found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination className="custom-pagination">
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)} className="active">
                  {number}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
              <Pagination.Last onClick={() => paginate(totalPages)} />
            </Pagination>
          </div>
        </div>
      </div>
      <AddLoanFrequencyModal show={isModalOpen} handleClose={handleCloseModal} />
      {selectedLoanFrequency && (
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Loan Frequency</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editLoanFrequencyName">
                <Form.Label className="pt-2">Loan Frequency</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedLoanFrequency.loanfrequency}
                  onChange={(e) => setSelectedLoanFrequency({ ...selectedLoanFrequency, loanfrequency: e.target.value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default LoanFrequencyList;
