import React, { useState, useEffect } from "react";
import "../screens/MemberPrint.css";
import { PhotoTable } from "./TablePrintFormat";
import { BASE_URL } from "../env";

const GeneratedEmiSchedule = (memberDetails) => {
  const [generatedSchedule, setGeneratedSchedule] = useState([]);
  const [totalInterest, setTotalInterest] = useState(0);

  useEffect(() => {
    handleGenerateSchedule(); // Call the function to generate the schedule
  }, []);

  const handleGenerateSchedule = () => {
    const expectedLoanAmount =
      memberDetails.memberDetails.memberDetails
        .sanctionedLoanAmountBySanctionCommittee;
    const rateOfInterest =
      memberDetails.memberDetails.proposedLoanDetails.rateOfInterest;
    const tenureInMonths =
      memberDetails.memberDetails.proposedLoanDetails.tenureInMonths;
    const emiDateValue = memberDetails.memberDetails.memberDetails
      .emiDateByBranchManager
      ? memberDetails.memberDetails.memberDetails.emiDateByBranchManager
      : memberDetails.memberDetails.proposedLoanDetails.emiDate;

    const monthlyRate = rateOfInterest / 12 / 100;
    const emi =
      (expectedLoanAmount *
        monthlyRate *
        Math.pow(1 + monthlyRate, tenureInMonths)) /
      (Math.pow(1 + monthlyRate, tenureInMonths) - 1);

    let balance = expectedLoanAmount;
    let beginingBalance = balance;
    let sumOfInterest = 0;
    const schedule = [];
    // Extract only the date from transactionDate
    let transactionDate = new Date(
      memberDetails.memberDetails.memberDetails.branchManagerStatusUpdatedAt
    );

    for (let month = 1; month <= tenureInMonths; month++) {
      const interest = balance * monthlyRate;
      const principal = emi - interest;
      beginingBalance = balance;
      balance -= principal;
      sumOfInterest += interest;

      // Calculate the EMI date for the current month
      const paymentDate = new Date(transactionDate);
      paymentDate.setMonth(transactionDate.getMonth() + month); // Add the current month to the transaction date
      const daysInMonth = new Date(
        paymentDate.getFullYear(),
        paymentDate.getMonth() + 1,
        0
      ).getDate(); // Get the last day of the month

      // Set the emiDate value (adjust to make sure it doesn't exceed the days in the month)
      if (emiDateValue > daysInMonth) {
        paymentDate.setDate(daysInMonth); // If emiDate is more than the last day, set it to the last day of the month
      } else {
        paymentDate.setDate(emiDateValue); // Otherwise, set it to the specified emiDate
      }

      schedule.push({
        month,
        emi,
        principal,
        interest,
        balance: balance > 0 ? balance : 0,
        emiDate: `${String(paymentDate.getDate()).padStart(2, "0")}-${String(
          paymentDate.getMonth() + 1
        ).padStart(2, "0")}-${paymentDate.getFullYear()}`, // Format as DD-MM-YYYY
        beginingBalance,
      });
    }
    setGeneratedSchedule(schedule);
    setTotalInterest(sumOfInterest);
  };

  return (
    <div>
      <div>
        <h1 className="section text-center">Repayment Schedule</h1>
        <div>
          <table
            className="table table-striped text-center table-bordered"
            style={{
              width: "100%",
              fontSize: "26px",
              borderCollapse: "collapse",
              border: "1.5px solid black",
            }}
          >
            <tbody>
              <tr className="section">
                <td
                  style={{
                    width: "30%",
                    border: "1.5px solid black",
                    padding: "2px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <b>Customer Name</b>
                      </div>
                      <div style={{ width: "50%" }}>
                        {memberDetails.memberDetails.memberDetails.memberName}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <b>Branch Name</b>
                      </div>
                      <div style={{ width: "50%" }}>
                        {memberDetails.memberDetails.branchName}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <b>Rate of Interest</b>
                      </div>
                      <div style={{ width: "50%" }}>
                        {
                          memberDetails.memberDetails.proposedLoanDetails
                            .rateOfInterest
                        }
                        %
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ width: "20%", border: "1.5px solid black" }}>
                  <img
                    src={`${BASE_URL}/images/${memberDetails.memberDetails.memberDocuments.memberPhoto}`}
                    alt=""
                    style={{ width: "150px", height: "150px" }}
                  />
                </td>
                <td style={{ width: "20%", border: "1.5px solid black" }}>
                  <img
                    src={`${BASE_URL}/images/${memberDetails.memberDetails.nomineeDocuments.nomineePhoto}`}
                    alt=""
                    style={{ width: "150px", height: "150px" }}
                  />
                </td>
                <td
                  style={{
                    width: "30%",
                    border: "1.5px solid black",
                    padding: "2px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <b>Customer ID</b>
                      </div>
                      <div style={{ width: "50%" }}>
                        {memberDetails.memberDetails.memberDetails.customerId}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <b>Loan Amount</b>
                      </div>
                      <div style={{ width: "50%" }}>
                        {memberDetails.memberDetails.memberDetails
                          .sanctionedLoanAmountBySanctionCommittee
                          ? memberDetails.memberDetails.memberDetails
                              .sanctionedLoanAmountBySanctionCommittee
                          : memberDetails.memberDetails.proposedLoanDetails
                              .loanAmount}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <b>Tenure</b>
                      </div>
                      <div style={{ width: "50%" }}>
                        {
                          memberDetails.memberDetails.proposedLoanDetails
                            .tenureInMonths
                        }{" "}
                        months
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="table-responsive">
            <table
              className="table table-striped text-center table-bordered"
              style={{
                fontSize: "26px",
                borderCollapse: "collapse",
                border: "1.5px solid black",
              }}
            >
              <thead className="primaryColor thead_sticky bg-primary">
                <tr className="section">
                  <th style={{ border: "1.5px solid black", padding: "0" }}>
                    S.No
                  </th>
                  <th style={{ border: "1.5px solid black", padding: "0" }}>
                    Repayment Date
                  </th>
                  <th style={{ border: "1.5px solid black", padding: "0" }}>
                    Principal
                  </th>
                  <th style={{ border: "1.5px solid black", padding: "0" }}>
                    Interest
                  </th>
                  <th style={{ border: "1.5px solid black", padding: "0" }}>
                    Total EMI
                  </th>
                  <th style={{ border: "1.5px solid black", padding: "0" }}>
                    Outstanding Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                {generatedSchedule.map((item, index) => (
                  <tr key={index} className="section">
                    <td style={{ border: "1.5px solid black", padding: "0" }}>
                      {index + 1}
                    </td>
                    <td style={{ border: "1.5px solid black", padding: "0" }}>
                      {item.emiDate}
                    </td>
                    <td style={{ border: "1.5px solid black", padding: "0" }}>
                      {Math.round(item.principal)}
                    </td>
                    <td style={{ border: "1.5px solid black", padding: "0" }}>
                      {Math.round(item.interest)}
                    </td>
                    <td style={{ border: "1.5px solid black", padding: "0" }}>
                      {Math.round(item.emi)}
                    </td>
                    <td style={{ border: "1.5px solid black", padding: "0" }}>
                      {Math.round(item.balance)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratedEmiSchedule;
