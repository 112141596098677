import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../env";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddLoanFrequencyModal = ({ show, handleClose }) => {
  const [loanFrequency, setLoanFrequency] = useState("");
  const [existingFrequencies, setExistingFrequencies] = useState([]);

  useEffect(() => {
    if (show) {
      fetchExistingLoanFrequencies();
    }
  }, [show]);

  const fetchExistingLoanFrequencies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/loan/frequencies`);
      setExistingFrequencies(response.data.loanFrequencies);
    } catch (error) {
      toast.error("Failed to fetch existing loan frequencies");
    }
  };

  const handleAddLoanFrequency = async () => {
    const normalizedLoanFrequency = loanFrequency.toLowerCase();
    const existingFrequencyNames = existingFrequencies.map(frequency => frequency.loanfrequency.toLowerCase());

    if (existingFrequencyNames.includes(normalizedLoanFrequency)) {
      toast.error("Loan Frequency already exists!");
      return;
    }

    try {
      await axios.post(`${BASE_URL}/add/loanfrequency`, { loanFrequency: normalizedLoanFrequency });
      setLoanFrequency("");
      handleClose();
      toast.success("Loan Frequency added successfully!");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <p className="add-martcategory-title"> Loan Frequency</p>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Enter Loan Frequency"
            value={loanFrequency}
            onChange={(e) => setLoanFrequency(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button className="bg-danger btn" style={{ color: 'white' }} onClick={handleClose}>
          Close
        </button>
        <button className="btn btn-addCredential" onClick={handleAddLoanFrequency}>
          Save
        </button>
      </Modal.Footer>
      <ToastContainer position="top-center" />
    </Modal>
  );
};

export default AddLoanFrequencyModal;
