import React, { useState } from "react";
import axios from "axios";
import { Form, Modal, Button } from "react-bootstrap";
import { BASE_URL } from "../env";

const UploadBranchManagerDocument = ({
  show,
  handleConfirm,
  handleClose,
  memberId,
  branchManagerId,
}) => {
  const [photo, setPhoto] = useState(null);
  const [photoName, setPhotoName] = useState("");
  // const [description, setDescription] = useState("");
  const description = "";
  const [comment, setComment] = useState("");
  const [validationError, setValidationError] = useState("");

  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleConfirmApprove = async (e) => {
    e.preventDefault();

    if (!photo) {
      setValidationError("Please select file to upload.");
      return;
    }
    if (!photoName) {
      setValidationError("Please enter a photoName.");
      return;
    }
    // if (!description) {
    //   setValidationError("Please enter a description.");
    //   return;
    // }
    if (!comment) {
      setValidationError("Please enter a comment.");
      return;
    }
    // // Check if the selected file is a PDF
    // if (photo && photo.type !== "application/pdf") {
    //   setValidationError("Only pdf are allowed.");
    //   return;
    // }

    const formData = new FormData();
    formData.append("member_id", memberId);
    formData.append("roleId", branchManagerId);
    formData.append("verificationPhoto", photo); // Single file
    formData.append("photoName", photoName);
    formData.append("description", description);
    formData.append("comment", comment);
    formData.append("photoUpdatedAt", new Date().toISOString());

    try {
      const response = await axios.post(
        `${BASE_URL}/api/branchmanager/uploadeddocuments`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true, // If cookies are needed
        }
      );
      console.log("Upload successful: ", response.data);
      handleConfirm(); // Call confirm handler on success
    } catch (error) {
      console.log("Error uploading photo: ", error);
      setValidationError("Error uploading photo: " + error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="full-width-modal">
      <Modal.Header closeButton>
        <Modal.Title>Upload Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {validationError && (
          <div className="alert alert-danger">{validationError}</div>
        )}
        <Form>
          <Form.Group>
            <Form.Label>Photo</Form.Label>
            <Form.Control
              type="file"
              accept="image/*,application/pdf"
              onChange={handleFileChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={photoName}
              onChange={(e) => setPhotoName(e.target.value)}
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirmApprove}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadBranchManagerDocument;
